import React from "react";
import { FaShoppingBag, FaUsers, FaListAlt } from "react-icons/fa";
import "../../pages/userListingMetrix/usermetrix.css";
import { Link } from "react-router-dom";

const Cards = ({ icon, title, amount, link, click }) => {
  const CardContent = (
    <div
      className="clickable-simple-card"
      style={{ cursor: "pointer" }}
      onClick={click}
    >
      <div className="metrixicon">{icon}</div>
      <div className="metrixdetails">
        <h4>{title}</h4>
        <h2>{amount}</h2>
      </div>
    </div>
  );

  return link ? (
    <Link to={link} style={{ cursor: "pointer", textDecoration: "none" }}>
      {CardContent}
    </Link>
  ) : (
    CardContent
  );
};

const SimpleCards = ({ cards }) => {
  return (
    <div>
      <div className="clickable-container">
        {cards?.map((metric, index) => (
          <Cards key={index} {...metric} />
        ))}
      </div>
    </div>
  );
};

export default SimpleCards;
