import React, { useRef, useContext } from "react";
import { LoginContext } from "../../../../App";

const FullscreenWrapper = ({ children }) => {
  const containerRef = useRef(null),
    { theme, setFullScreen } = useContext(LoginContext);

  // Function to toggle fullscreen
  const toggleFullScreen = () => {
    const container = containerRef.current;
    if (container) {
      if (!document.fullscreenElement) {
        setFullScreen(true);
        if (container.requestFullscreen) {
          container.requestFullscreen().catch((err) => {
            console.error(`Error enabling full-screen mode: ${err.message}`);
          });
        } else if (container.webkitRequestFullscreen) {
          /* Safari */
          container.webkitRequestFullscreen();
        } else if (container.msRequestFullscreen) {
          /* IE11 */
          container.msRequestFullscreen();
        }
      } else {
        document.exitFullscreen();
        setFullScreen(false);
      }
    }
  };

  return (
    <div
      id=""
      ref={containerRef}
      style={{
        cursor: "pointer",
      }}
      onClick={toggleFullScreen}
    >
      {children}
    </div>
  );
};

export default FullscreenWrapper;
