import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";
import { hasPermission } from "../../../helperPermissionEditor";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import CustomModal from "../common-components/customModal";
import { LuEye } from "react-icons/lu";
import NoDataFoundPage from "../common-components/NoDataFound";

const HashTagListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [hashtags, setHashTags] = useState([]),
    [isEditing, setIsEditing] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [bookOptions, setBookOptions] = useState([]),
    [textOptions, setTextOptions] = useState([]),
    [record, setRecord] = useState({
      name: "",
      text: "",
      bookId: "",
      clientId: "",
      labelId: "",
      accountManagerId: "",
      postType: "",
    }),
    [view, setView] = useState({
      visible: false,
      data: {},
    }),
    [options, setOptions] = useState([]),
    [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRecord({
      name: "",
      text: "",
      bookId: "",
      clientId: "",
      labelId: "",
      accountManagerId: "",
      postType: "",
    });
    setIsEditing("");
    setView({});
  };
  const navigate = useNavigate();

  const getListing = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/hashtags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setHashTags(response.data.hashtags);
      setTotalPages(response.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  useEffect(() => {
    getListing(currentPage);
    getDropDownOptions();

    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage]);

  const getDropDownOptions = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(`/api/hashtags/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const bookDropDown = response?.data?.book_list.map((book) => ({
        id: book.id,
        name: book.book_name,
      }));

      const textDropDown = response?.data?.text_list.map((text) => ({
        id: text.id,
        name: text.name,
      }));

      setBookOptions(bookDropDown);
      setTextOptions(textDropDown);

      setOptions(response?.data?.label || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.message || "Error creating hashtag");
      } else {
        console.error("Error creating hashtag:", error);
        toast.error("Failed to create hashtag. Please try again later.");
      }
    }
  };
  // get Data for view modal
  const getViewData = async (row) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(`/api/hashtags/${row.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 201) {
        const { hashtag } = response.data;
        setView({
          visible: true,
          data: hashtag,
        });

        setShow(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.message || "Error view hashtag");
      } else {
        console.error("Error view hashtag:", error);
        toast.error("Failed to view hashtag. Please try again later.");
      }
    }
  };
  const handleEdit = async (row) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(`/api/hashtags/${row.id}/edit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      setShow(true);

      const { name, hashtag, book_id, text_id } = response.data.hashtag;

      setIsEditing(response.data.hashtag);

      setRecord((prevState) => ({
        ...prevState,
        name,
        hashtag,
        bookId: book_id,
        textId: text_id,
      }));
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching record for edit:", error);
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");

    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/hashtags/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setHashTags(hashtags.filter((sound) => sound.id !== id));

      toast.success("Record deleted successfully!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
      } else {
        console.error("Error deleting Record:", error);
        toast.error("Error deleting Record");
      }
    }
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    const updatedData = {
      name: record.name,
      hashtag: record.hashtag,
      book_id: record.bookId,
      text_id: record.textId,
    };

    try {
      const response = await axiosInstance.put(
        `/api/hashtags/${isEditing.id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleClose();
      toast.success("Record updated successfully");
      getListing();

      if (response.status === 200) {
        setIsEditing("");
      } else {
        console.error("Error while updating:", response);
      }
    } catch (error) {
      console.error("Error while updating:", error);
      toast.error(error.response.data.message || "Please try again");
    }
  };

  const handleCreate = async () => {
    const { name, hashtag, bookId, textId } = record;

    // Validate the form fields
    if (!name || !hashtag || !bookId || !textId) {
      toast.error("Please fill in all the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      name: name,
      hashtag: hashtag,
      book_id: bookId,
      text_id: textId,
    };

    try {
      await axiosInstance.post(`/api/hashtags`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      getListing();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating record");
      console.error("Error creating record:", error);
    }
  };

  const getTableUI = () => {
    return (
      <table className="user-table mt-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Hashtag</th>
            <th>Book</th>
            <th>Text</th>
            <th>Updated By</th>
            <th>Updated At</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {hashtags.length > 0 ? (
            hashtags.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.name}</td>
                <td>{row.hashtag}</td>
                <td>{row.book}</td>
                <td>{row.text}</td>
                <td>{row.updated_by}</td>
                <td>{row.updated_at}</td>
                <td className="d-flex align-items-center justify-content-center">
                  <Toaster position="top-center" richColors />
                  <button
                    data-tooltip-id="view-button"
                    className="edit me-2"
                    onClick={() => getViewData(row)}
                    style={{
                      padding: "4px 10px",
                      fontSize: "22px",
                    }}
                    disabled={!hasPermission(user?.permissions, "view-hashtag")}
                  >
                    <LuEye />
                  </button>
                  <ReactTooltip
                    id="view-button"
                    place="bottom"
                    content="View Tiktok"
                  />

                  <span
                    className="px-2 edit me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <TbEdit
                      size={24}
                      data-tooltip-id="editTooltip"
                      data-tooltip-content="Edit"
                      onClick={() => handleEdit(row)}
                      disabled={
                        !hasPermission(user?.permissions, "update-hashtag")
                      }
                    />
                    <ReactTooltip id="editTooltip" />
                  </span>

                  <span
                    className="px-2"
                    style={{
                      cursor: "pointer",
                      background: "red",
                      color: "white",
                      fontSize: "16px",
                      padding: "8px 10px",
                      borderRadius: "8px",
                    }}
                  >
                    <MdDelete
                      size={24}
                      data-tooltip-id="deleteTooltip"
                      data-tooltip-content="Delete"
                      onClick={() => handleDelete(row.id)}
                      disabled={
                        !hasPermission(user?.permissions, "delete-hashtag")
                      }
                    />
                    <ReactTooltip id="deleteTooltip" />
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                <NoDataFoundPage />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const getFormUI = () => {
    return view?.visible ? (
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Hashtag</th>
            <th>Book ID</th>
            <th>Text ID</th>
          </tr>
        </thead>
        <tbody>
          {[view.data].map((record, i) => (
            <tr key={i}>
              <td>{record?.id}</td>
              <td>{record.name}</td>
              <td>{record.hashtag}</td>
              <td>{record.book_id}</td>
              <td>{record.text_id}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div style={{ justifyContent: "center !important" }}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Control
            type="text"
            placeholder="Enter Text"
            value={record.name}
            onChange={(e) => setRecord({ ...record, name: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formText">
          <Form.Control
            type="text"
            placeholder="Enter Hashtags"
            value={record.hashtag}
            onChange={(e) => setRecord({ ...record, hashtag: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBookDropdown">
          <Form.Select
            value={record.bookId}
            onChange={(e) =>
              setRecord({
                ...record,
                bookId: e.target.value,
              })
            }
          >
            <option>Book Options</option>
            {bookOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formLabelDropdown">
          <Form.Select
            value={record.textId}
            onChange={(e) =>
              setRecord({
                ...record,
                textId: e.target.value,
              })
            }
          >
            <option>Text Options</option>
            {textOptions.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Hashtag"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => setShow(true)}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
          {totalPages > 1 && (
            <div className="pagination">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                pageStart={pageStart}
              />
            </div>
          )}
        </div>
      </div>

      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div>
            {view?.visible ? (
              <h2 className="create_user_heading"> Details </h2>
            ) : (
              <h2 className="create_user_heading">{`${
                isEditing ? "Update" : "Create"
              } Hashtag`}</h2>
            )}
          </div>
        }
        bodyContent={getFormUI()}
        footerContent={
          <>
            <div className="d-flex justify-content-center align-items-center">
              {!view?.visible && (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={isEditing ? handleUpdate : handleCreate}
                >
                  {`${isEditing ? "Update" : "Create"} Hashtag`}
                </Button>
              )}
              <Button
                variant="secondary"
                className="creatrUserClose"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default HashTagListing;
