import React from "react";
import { SelectPicker } from "rsuite";

const YearFilter = ({ selectedYear, onChange }) => {
  const currentYear = new Date().getFullYear();

  const yearOptions = Array.from({ length: 4 }, (_, i) => ({
    label: `${currentYear - i}`,
    value: currentYear - i,
  }));

  return (
    <SelectPicker
      data={yearOptions}
      searchable={false}
      placeholder="Select Year"
      style={{ minWidth: 224, width: "100%" }}
      value={selectedYear}
      onChange={onChange}
    />
  );
};

export default YearFilter;
