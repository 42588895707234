import React, { useEffect, useState, useContext, useMemo } from "react";
import { Line } from "react-chartjs-2";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { useParams } from "react-router-dom";
import DaysFilter from "./component/DaysFilter";
import moment from "moment";
import subDays from "date-fns/subDays";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DaysFilterOLD from "../common-components/DateFilterOld";
import { LoginContext } from "../../../App";
import { colors } from "../../../utils/constants";
import NoDataFoundPage from "../common-components/NoDataFound";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TikTokVideoStatistics = () => {
  const { selectedDays, setSelectedDays, theme } = useContext(LoginContext),
    { videoId } = useParams(),
    [videoStatistics, setVideoStatistics] = useState([]),
    [loading, setLoading] = useState(true),
    [sortConfig, setSortConfig] = useState({ key: null, direction: null }),
    [error, setError] = useState(null);

  const fetchVideoStatistics = async () => {
    setLoading(true);

    const token = localStorage.getItem("token");
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");

    try {
      const response = await axiosInstance.post(
        "/api/tiktok/video-statistics",
        { from, to, video_id: videoId, days: null },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (
        response.data.message ===
          "TikTok video statistics fetched successfully!" &&
        response.data.statistics.length > 0
      ) {
        setVideoStatistics(response.data.statistics);
        setError(null);
        setLoading(false);
      } else {
        setError("No statistics found.");
        setLoading(false);
      }
    } catch (err) {
      setError("Failed to fetch TikTok video statistics");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVideoStatistics();
  }, [videoId, selectedDays]);

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          color: theme === "dark" ? "#ffffff" : "#000000",
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: true,
        text: "TikTok Video Statistics",
        font: {
          size: 16,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const generateChartData = () => {
    const labels = videoStatistics.map((item) =>
      moment(item.created_at).format("DD MMM, YY")
    );
    // const labels = videoStatistics.map((stat) => stat.created_at);
    const data = {
      labels,
      datasets: [
        {
          label: "Views",
          data: videoStatistics.map((stat) => stat.views),
          backgroundColor: "rgba(54, 162, 235, 0.2)",
          borderColor: colors.blue,
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y",
        },
        {
          label: "Likes",
          data: videoStatistics.map((stat) => stat.likes),
          backgroundColor: "rgba(255, 206, 86, 0.2)",
          borderColor: colors.pink,
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y1",
        },
        {
          label: "Comments",
          data: videoStatistics.map((stat) => stat.comments),
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: colors.sea_green,
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
          yAxisID: "y1",
        },
        {
          label: "Shares",
          data: videoStatistics.map((stat) => stat.shares),
          backgroundColor: colors.light_yellow,
          borderColor: colors.yellow,
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: colors.light_yellow,
          yAxisID: "y1",
        },
        {
          label: "Saved",
          data: videoStatistics.map((stat) => stat.saved),
          backgroundColor: "rgba(153,102,255, 0.2)",
          borderColor: colors.purple,
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: "rgba(153,102,255, 0.1)",
          yAxisID: "y1",
        },
      ],
    };
    return data;
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  const sortedVideoStats = useMemo(() => {
    if (!sortConfig.key) return videoStatistics;

    const sortedData = [...videoStatistics];
    sortedData.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortedData;
  }, [videoStatistics, sortConfig]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return null;
  };

  return (
    <div className="userLists">
      <CommonHeader dashboardName={videoId + " - Video Statistics"} />
      <DaysFilter selectedDays={selectedDays} onChange={onChange} />

      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : error ? (
        <div className="error-overlay">
          <p>{error}</p>
        </div>
      ) : (
        <div className="chart-wrapper">
          {videoStatistics.length > 0 ? (
            <Line data={generateChartData()} options={options} />
          ) : (
            <p>No video statistics available.</p>
          )}
        </div>
      )}
      <div className="mt-5 mb-5">
        <p className="text-center">TikTok Video Statistics</p>
        <table className="user-table">
          <thead>
            <tr>
              <th
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("views")}
              >
                Views &nbsp;
                {getArrow("views")}
              </th>
              <th
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("likes")}
              >
                Likes &nbsp;
                {getArrow("likes")}
              </th>
              <th
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("comments")}
              >
                Comments &nbsp;
                {getArrow("comments")}
              </th>
              <th
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("shares")}
              >
                Shares &nbsp;
                {getArrow("shares")}
              </th>
              <th
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("saved")}
              >
                Saved &nbsp;
                {getArrow("saved")}
              </th>
              <th
                style={{ cursor: "pointer" }}
                onClick={() => handleSort("created_at")}
              >
                Created At &nbsp;
                {getArrow("created_at")}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedVideoStats.length > 0 ? (
              sortedVideoStats.map((stat, index) => (
                <tr
                  className=""
                  key={stat.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                  }}
                >
                  <td>{stat.views}</td>
                  <td>{stat.likes}</td>
                  <td>{stat.comments}</td>
                  <td>{stat.shares}</td>
                  <td>{stat.saved}</td>
                  <td>{moment(stat.created_at).format("YYYY-MM-DD")}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="11">
                  <NoDataFoundPage />
                </td>
              </tr>
            )}
            {/* } */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TikTokVideoStatistics;
