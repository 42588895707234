import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
// import "../../pages/page.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../../../../loader/CommonLoader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonHeader from "../../common-components/CommonHeader";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../../App";

const FreelancerClientsText = () => {
  const { user } = useContext(LoginContext),
    [data, setData] = useState([]),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    navigate = useNavigate(),
    { id } = useParams();

  const getListing = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.post(
        `/api/dashboard/texts`,
        { client_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const apiData = response.data.texts;

      setData(apiData);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getListing();

    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const getTableUI = () => {
    return (
      <div className="details mt-4">
        <table className="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Text</th>
              <th>Status</th>
              <th>Book</th>
              <th>Client</th>
              <th>Account Manager</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data.map((row, i) => (
                <tr key={row.id}>
                  <td>{i + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.text}</td>
                  <td>{row.book}</td>
                  <td>{row.client}</td>
                  <td>{row.account_manager}</td>
                  <td>
                    <Toaster position="top-center" richColors />
                    <button
                      data-tooltip-id="view-button"
                      className="edit me-2"
                      onClick={() => {
                        navigate(`/showHashTag/${"text"}/${row?.id}`, {
                          state: {
                            cameFrom: "text",
                            id: row?.id,
                          },
                        });
                      }}
                    >
                      Show HashTag by Text id
                    </button>
                    <ReactTooltip
                      id="view-button"
                      place="bottom"
                      content="Show Books"
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No Records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Freelancer Client Text"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => {}}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
        </div>
      </div>

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default FreelancerClientsText;
