import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const DeleteModal = ({ toggleState, toggleHandler, apiCallHandleronClick }) => {
  return toggleState ? (
    <>
      <Modal show={toggleState} onHide={toggleState}>
        <Modal.Header className="pb-2 text-center justify-content-left mb-1 h4 border-bottom">
          Warning
        </Modal.Header>

        <Modal.Body className="d-flex flex-column pt-2 justify-content-center align-items-left h6">
          Are you sure you want to delete this record?
        </Modal.Body>

        <Modal.Footer className="justify-content-right pt-2 border-top">
          <Button
            variant="warning"
            className="creatrUserSubmit me-3"
            onClick={apiCallHandleronClick}
          >
            Delete
          </Button>
          <Button
            variant="secondary"
            className="creatrUserClose"
            onClick={toggleHandler}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    ""
  );
};

export default DeleteModal;
