import React, { useState, useContext } from "react";
import "./Header.css";
import axiosInstance, {
  clearAllTimeouts,
} from "../../../../refershTokenManage/axiosConfig";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { hasPermission } from "../../../../helperPermissionEditor";
import { LoginContext } from "../../../../App";
import { Toaster, toast } from "sonner";
import { FaTiktok } from "react-icons/fa";
import { FaVideo } from "react-icons/fa";
import { GiSoundOn } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import { TbApi } from "react-icons/tb";
import CommonLoader from "../../../../loader/CommonLoader";
import { FaMoon } from "react-icons/fa";
import { GoSun } from "react-icons/go";
import { returnBackButton } from "../../../../utils/helperfunctions";
import { IoChevronBack, IoChevronForwardSharp } from "react-icons/io5";
import { BsFacebook } from "react-icons/bs";

const Header = () => {
  const { setUser, theme, toggleTheme, isSidebarOpen, setIsSidebarOpen } =
      useContext(LoginContext),
    userName = localStorage.getItem("userName"),
    userRole = localStorage.getItem("userRole"),
    [showResetModal, setShowResetModal] = useState(false),
    [newPassword, setNewPassword] = useState(""),
    [currentPassword, setCurrentPassword] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [validationError, setValidationError] = useState(""), // State for validation error messag,
    location = useLocation();

  const isActive = (path) => location.pathname === path;

  const getLinkClass = (path) => {
    return `text-decoration-none ${
      isActive(path) ? "dashboard-header active-link" : "dashboard-link"
    }`;
  };

  const activeLinkStyle = {
    backgroundColor: "#f5f5f5",
    color: "#262626",
    padding: "5px",
    "border-radius": "10px",
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      await axiosInstance.post(
        `/api/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      clearAllTimeouts();
      localStorage.removeItem("token");
      localStorage.clear();
      setUser({});
      setIsLoading(false);
    } catch (error) {
      // check for if token is expired or session logout
      if (error.response.status === 401) {
        window.location.href = "/";
      }
      console.error("Logout error:", error);
      localStorage.removeItem("token");
      localStorage.clear();
      setIsLoading(false);
    }
  };

  const handleResetPassword = async () => {
    // Validation check for empty fields
    if (!currentPassword || !newPassword) {
      setValidationError("Please fill out both fields.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axiosInstance.post(
        `/api/change-password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Password updated successfully");
      setShowResetModal(false);
      setNewPassword("");
      setCurrentPassword("");
      setValidationError("");
    } catch (error) {
      console.error("Password change error:", error);
    }
  };

  return (
    <div className="sticky-element">
      <header>
        <div className="headerContainer d-flex justify-content-between p-2">
          <div class="align-items-center justify-content-center">
            {isSidebarOpen ? (
              <IoChevronBack
                style={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              />
            ) : (
              <IoChevronForwardSharp
                style={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              />
            )}
          </div>
          <div className="navLink">
            <ul className="d-flex nav-list m-0 list-unstyled align-content-center ">
              <li>
                <button
                  data-tooltip-id="dark-light-mode"
                  onClick={() => toggleTheme()}
                  style={{
                    fontSize: "22px",
                    backgroundColor: "unset",
                  }}
                >
                  {theme === "light" ? <FaMoon /> : <GoSun />}
                </button>
              </li>
              <li>
                <a
                  href={
                    "https://www.facebook.com/groups/gyptiktokforauthors/learning_content"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontSize: "26px",
                    backgroundColor: "unset",
                  }}
                >
                  <BsFacebook />
                </a>
              </li>
              {/* <li>
                <img
                  src={
                    "https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/e49ea08a7ecdaf2a437d2e0a2bfe87b4~c5_1080x1080.webp?lk3s=a5d48078&nonce=31574&refresh_token=3d0a1619f9d347f0e363e7000f10bf5e&x-expires=1730916000&x-signature=7qGVmN9A64dnfII3eC%2FUaeqkjg0%3D&shp=a5d48078&shcp=2472a6c6"
                  }
                  alt="loading"
                  width="50"
                  height="50"
                  style={{ borderRadius: "50%" }}
                />
              </li> */}
              <li>
                <Dropdown className="me-4 fs-4  fw-bold p-1 rounded brand dropdown-basic">
                  <Dropdown.Toggle
                    variant="light"
                    className="fw-bold"
                    id="dropdown-basic"
                    style={{
                      border: "none !important",
                      backgorundColor: "unset !important",
                    }}
                  >
                    {userName}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Toaster richColors position="top-center" />
                    <Dropdown.Item onClick={() => setShowResetModal(true)}>
                      Reset password
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Modal
                  show={showResetModal}
                  onHide={() => {
                    setShowResetModal(false);
                    setValidationError("");
                  }}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group controlId="formCurrentPassword">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter current password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formNewPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Form.Group>
                    {validationError && (
                      <p className="text-danger">{validationError}</p>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowResetModal(false);
                        setValidationError("");
                      }}
                    >
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleResetPassword}>
                      Save Password
                    </Button>
                  </Modal.Footer>
                </Modal>
              </li>
            </ul>
            {/* <span className="userLoginDetails">
            {isLoading ? (
              <CommonLoader />
            ) : (
              <div>
                <Dropdown className="me-4 fs-4  fw-bold p-1 rounded brand dropdown-basic">
                  <Dropdown.Toggle
                    variant="light"
                    className="fw-bold"
                    id="dropdown-basic"
                    style={{
                      border: "none !important",
                      backgorundColor: "unset !important",
                    }}
                  >
                    {userName}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Toaster richColors position="top-center" />
                    <Dropdown.Item onClick={() => setShowResetModal(true)}>
                      Reset password
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Modal
                  show={showResetModal}
                  onHide={() => {
                    setShowResetModal(false);
                    setValidationError("");
                  }}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group controlId="formCurrentPassword">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter current password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formNewPassword">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Form.Group>
                    {validationError && (
                      <p className="text-danger">{validationError}</p>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowResetModal(false);
                        setValidationError("");
                      }}
                    >
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleResetPassword}>
                      Save Password
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          </span> */}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
