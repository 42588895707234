import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import subDays from "date-fns/subDays";

const DaysFilter = ({ selectedDays, onChange }) => {
  const [ranges, setRanges] = useState([]);

  useEffect(() => {
    setRanges(
      [7, 15, 30, 60, 90].map((days) => ({
        label: `Last ${days} days`,
        value: [subDays(new Date(), days - 1), new Date()],
        placement: "left",
      }))
    );
  }, []);

  const renderCustomValue = (value) => {
    const [start, end] = value || [];
    const formatDate = (date) => (date ? date.toLocaleDateString() : "");

    const range = ranges?.find(
      (range) =>
        range.value[0].toDateString() === start?.toDateString() &&
        range.value[1].toDateString() === end?.toDateString()
    );

    return range ? range.label : `${formatDate(start)} - ${formatDate(end)}`;
  };

  return (
    <div className="days-filter mt-2">
      <DateRangePicker
        format="yyyy-MM-dd"
        character=" - "
        showOneCalendar
        style={{
          height: "50px !important",
          padding: "0px",
        }}
        value={selectedDays}
        onChange={onChange}
        placeholder="Select Date Range"
        ranges={ranges}
        renderValue={renderCustomValue}
      />
    </div>
  );
};

export default DaysFilter;
