import React from "react";
import { FaShoppingBag, FaUsers, FaListAlt } from "react-icons/fa";
import "./usermetrix.css";
import { Link } from "react-router-dom";

const MetricCard = ({ icon, title, amount, link }) => {
  const CardContent = (
    <div className="metric-card">
      <div className="metrixicon">{icon}</div>
      <div className="metrixdetails">
        <h4>{title}</h4>
        <h2>{amount}</h2>
      </div>
    </div>
  );

  return link ? (
    <Link to={link} style={{ cursor: "pointer", textDecoration: "none" }}>
      {CardContent}
    </Link>
  ) : (
    CardContent
  );
};

const MetrixCards = ({ cards }) => {
  return (
    <div>
      <div className="metrics-container">
        {cards?.map((metric, index) => (
          <MetricCard key={index} {...metric} />
        ))}
      </div>
    </div>
  );
};

export default MetrixCards;
