import React, { useState, useContext } from "react";
import "../login.css";
import { LoginContext } from "../../../App";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import ReCAPTCHA from "react-google-recaptcha";

const SignUpAsAccountManager = () => {
    const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
    const [newUser, setNewUser] = useState({
        name: "",
        email: "",
        password: "",
        c_password: "",
    }),
        [errorMessage, setErrorMessage] = useState(""),
        { setCreateAccountManager, setUser } = useContext(LoginContext),
        [loading, setLoading] = useState(false),
        navigate = useNavigate();
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleSignup = async (e) => {
        try {
            e.preventDefault();
            if (!recaptchaToken) {
                alert("Please complete the reCAPTCHA!");
                return;
            }
            setLoading(true);
            const { name, email, password, c_password, phone, address } = newUser;
            const response = await axiosInstance.post("api/register-as-account-manager", {
                name,
                email,
                password,
                c_password,
                phone,
                address,
                recaptchaToken
            });

            if (response.status === 201) {
                const { name, roles, permissions } = response.data.user;
                const token = response.data.accessToken;
                localStorage.setItem("userName", name);
                localStorage.setItem("token", token);

                localStorage.setItem("userRole", roles[0].name);
                localStorage.setItem("permissions", JSON.stringify(permissions));

                setUser({ email, isLoggedIn: true, permissions });
                resetFields();
                toast.success("Successfully created user!");
                setTimeout(navigate("/dashboard"), 10000);
            }
            setLoading(false);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("Something went wrong. Please try again later.");
            }
            setLoading(false);
        }
    };
    const resetFields = () => {
        setNewUser({
            name: "",
            email: "",
            password: "",
            c_password: "",
        });
    };

    return (
        <div className="main">
            <div className="arrow arrow--top">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="270.11"
                    height="649.9"
                    overflow="visible"
                >
                    <style>
                        {`.geo-arrow {
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }`}
                    </style>
                    <g className="item-to bounce-1">
                        <path
                            className="geo-arrow draw-in"
                            d="M135.06 142.564L267.995 275.5 135.06 408.434 2.125 275.499z"
                        />
                    </g>
                    <circle
                        className="geo-arrow item-to bounce-2"
                        cx="194.65"
                        cy="69.54"
                        r="7.96"
                    />
                    <circle
                        className="geo-arrow draw-in"
                        cx="194.65"
                        cy="39.5"
                        r="7.96"
                    />
                    <circle
                        className="geo-arrow item-to bounce-3"
                        cx="194.65"
                        cy="9.46"
                        r="7.96"
                    />
                    <g className="geo-arrow item-to bounce-2">
                        <path
                            className="st0 draw-in"
                            d="M181.21 619.5l13.27 27 13.27-27zM194.48 644.5v-552"
                        />
                    </g>
                </svg>
            </div>
            <div className="arrow arrow--bottom">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="31.35"
                    height="649.9"
                    overflow="visible"
                >
                    <style>
                        {`.geo-arrow {
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-miterlimit: 10;
          }`}
                    </style>
                    <g className="item-to bounce-1">
                        <circle
                            className="geo-arrow item-to bounce-3"
                            cx="15.5"
                            cy="580.36"
                            r="7.96"
                        />
                        <circle
                            className="geo-arrow draw-in"
                            cx="15.5"
                            cy="610.4"
                            r="7.96"
                        />
                        <circle
                            className="geo-arrow item-to bounce-2"
                            cx="15.5"
                            cy="640.44"
                            r="7.96"
                        />
                        <g className="item-to bounce-2">
                            <path
                                className="geo-arrow draw-in"
                                d="M28.94 30.4l-13.26-27-13.27 27zM15.68 5.4v552"
                            />
                        </g>
                    </g>
                </svg>
            </div>
            <div className="main__text-wrapper">
                <form className="form p-3 p-md-5 sign-up-page-bg">
                    <h3 className="mb-2 mb-md-5 sign-up-page">Sign Up As</h3>
                    <h2 className="mb-5 fs-2 sign-up-page">Account Manager To Sign In </h2>

                    <div style={{ justifyContent: "center !important" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                type="text"
                                placeholder="Enter Your Name "
                                autoFocus
                                value={newUser?.name}
                                onChange={(e) =>
                                    setNewUser({ ...newUser, name: e.target.value })
                                }
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                type="email"
                                className="creatuserListing"
                                placeholder="name@example.com"
                                autoFocus
                                value={newUser?.email}
                                onChange={(e) =>
                                    setNewUser({ ...newUser, email: e.target.value })
                                }
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                type="password"
                                className="creatuserListing"
                                autoFocus
                                value={newUser?.password}
                                onChange={(e) =>
                                    setNewUser({ ...newUser, password: e.target.value })
                                }
                                placeholder="Password"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Control
                                type="password"
                                className="creatuserListing"
                                autoFocus
                                value={newUser?.c_password}
                                onChange={(e) =>
                                    setNewUser({ ...newUser, c_password: e.target.value })
                                }
                                placeholder="Confirm Password"
                                required
                            />
                        </Form.Group>
                    </div>
                    {/* END */}
                    {errorMessage && (
                        <div className="error-message text-danger fw-bold fs-5 text-center mt-3">
                            {errorMessage}
                        </div>
                    )}
                    <div className="recaptcha">
                        <ReCAPTCHA
                            sitekey={RECAPTCHA_SITE_KEY}
                            onChange={handleRecaptchaChange}
                        />
                    </div>
                    <div className="button">
                        <button
                            className="submit"
                            onClick={handleSignup}
                            disabled={loading}
                        >
                            Sign Up
                        </button>
                    </div>
                    <div className="newuser sign-up-page">
                        Already have an account?
                        <span className="ms-2 sign-up-page" onClick={() => setCreateAccountManager(false)}>
                            Sign In
                        </span>
                    </div>
                </form>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="dotted-circle"
                    width="352"
                    height="352"
                    overflow="visible"
                >
                    <circle
                        cx="176"
                        cy="176"
                        r="174"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeDasharray="12.921,11.9271"
                    />
                </svg>
            </div>
        </div>
    );
};

export default SignUpAsAccountManager;
