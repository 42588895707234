import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit, TbRestore } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Alert from "react-bootstrap/Alert";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { hasPermission } from "../../../helperPermissionEditor";
import { LoginContext } from "../../../App";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import { Link, useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import AddMoreList from "../common-components/addMoreList";
import { AiOutlineEnter } from "react-icons/ai";
import { clientHeadings } from "../../../utils/constants";
import moment from "moment";
import CustomModal from "../common-components/customModal";
import { FaUserPlus } from "react-icons/fa";
import { CiSettings } from "react-icons/ci";
import DeleteModal from "../common-components/deleteModal";
import NoDataFoundPage from "../common-components/NoDataFound";

const userData = {
  name: "",
  email: "",
  phone: "",
  password: "",
  address: "",
  tiktok: "",
  status: "",
  id: "",
};
const settingData = {
  visible: false,
  tiktok: 0,
  instagram: 0,
  clientId: 0,
};
const ClientListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [client, setClient] = useState([]),
    [isEditing, setIsEditing] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [editedStatus, setEditedStatus] = useState(""),
    [selectedClientId, setSelectedClientId] = useState(null),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [newUser, setNewUser] = useState(userData),
    [list, setList] = useState([]),
    [show, setShow] = useState(false),
    // [deleteClient, setDeleteClient] = useState(false),
    [selectedRecord, setSelectedRecord] = useState({
      id: "",
      modal: false,
    }),
    [edit, setEdit] = useState(""),
    [view, setView] = useState({
      visible: false,
    }),
    [addEditModal, setAddEditModal] = useState(false),
    [settings, setSettings] = useState(settingData),
    navigate = useNavigate(),
    [handleRestore, setHandleRestore] = useState(false);

  const resetFields = () => {
    setNewUser(userData);
    setList([]);
  };

  const handleEditList = (e, record) => {
    setEdit(record);

    setNewUser((prevState) => ({
      ...prevState,
      tiktok: record.tiktok,
    }));
    setEditedStatus(record.status);
  };

  const handleDeleteList = async (e, record) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      await axiosInstance.delete(
        `/api/tiktoks/${record.id}`,
        {
          params: {
            client_id: record.client_id,
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      getTiktoks(record.client_id);
      toast.success("TikTok account deleted successfully!");
    } catch (error) {
      setList(list);
      toast.error("Error deleting TikTok account");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestoreTiktok = async (e, record) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      await axiosInstance.put(
        `/api/tiktoks/${record.id}/restore`,
        {
          client_id: record.client_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("TikTok account restored successfully!");
      getTiktoks(record.client_id);
      setHandleRestore(false);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error restoring TikTok account"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    resetFields();
    setView({});
    setEditedStatus("");
    setSettings(settingData);
    setEdit("");
    setAddEditModal(false);
  };

  const fetchClient = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true); // Start loading
    try {
      const response = await axiosInstance.get(`/api/clients?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClient(response.data.clients);
      setTotalPages(response.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
    setIsLoading(false); // End loading
  };

  const getSettings = async (id) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/settings/${id.toString()}/social-media`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.settings && Object.values(response.data?.settings)) {
          const { tiktok, instagram } = response.data?.settings;
          setSettings({
            visible: true,
            tiktok,
            instagram,
            clientId: id,
          });
        } else {
          setSettings({
            ...settings,
            visible: true,
            clientId: id,
          });
        }
      }
      setShow(true);
    } catch (error) {
      setSettings(settingData);
      setShow(false);
    }
    setIsLoading(false);
  };

  const getTiktoks = async (id) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/tiktoks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          client_id: id,
        },
      });

      if (response.data?.tiktoks.length) {
        setList(response?.data?.tiktoks);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        setSettings({
          visible: true,
          tiktok: 0,
          instagram: 0,
          clientId: id,
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchClient(currentPage);
    const intervalId = setInterval(() => {
      fetchClient();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const handleEdit = (record) => {
    setShow(true);
    setAddEditModal(true);

    setNewUser({
      name: record.name,
      email: record.email,
      phone: record.phone,
      address: record.address,
      user_id: record.user_id,
      status: record.status,
      id: record.id,
    });
    setEditedStatus(record.status);
  };

  const handleView = async (record) => {
    const { tiktoks } = record;
    let client_id;
    if (tiktoks.length) {
      client_id = tiktoks[0]?.client_id;
    } else {
      client_id = record.id;
    }
    await getTiktoks(client_id);

    setView({
      clientId: client_id,
      visible: true,
    });
    setShow(true);
  };

  // handleSettings
  const handleSettings = async (record) => {
    const { id } = record;
    setSettings((prevStatus) => ({
      ...prevStatus,
      visible: true,
    }));
    await getSettings(id);
  };

  const updateStatus = async (event) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    const { clientId, tiktok, instagram } = settings;

    const name = event.target.id,
      value = event.target.checked ? 1 : 0;

    let soialMediaPayload = {
      instagram: 0,
      tiktok: 0,
      client_id: 0,
    };
    function checkAndUpdateStates(error = false) {
      if (name === "tiktok") {
        soialMediaPayload = {
          instagram: instagram,
          tiktok: !error ? value : !value,
          client_id: !clientId ? settings.clientId : clientId,
        };
      } else if (name === "instagram") {
        soialMediaPayload = {
          tiktok: tiktok,
          instagram: !error ? value : !value,
          client_id: !clientId ? settings.clientId : clientId,
        };
      }

      setSettings((prevState) => ({
        ...prevState,
        tiktok: Number(soialMediaPayload.tiktok),
        instagram: Number(soialMediaPayload.instagram),
        clientId: Number(soialMediaPayload.client_id),
      }));
    }
    checkAndUpdateStates();

    soialMediaPayload.instagram = soialMediaPayload.instagram?.toString();
    soialMediaPayload.tiktok = soialMediaPayload.tiktok?.toString();
    soialMediaPayload.client_id = soialMediaPayload.client_id?.toString();

    try {
      await axiosInstance.post(
        `/api/settings/social-media`,
        soialMediaPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setIsLoading(false);
      toast.success("Successfully Updated");
      fetchClient();
    } catch (error) {
      setIsLoading(false);
      checkAndUpdateStates(true);
      console.error("Error while updating", error);
    }
  };

  const handleDeleteAPICall = async (clientId) => {
    const token = localStorage.getItem("token");

    setIsLoading(true); // Start loading
    try {
      await axiosInstance.delete(`/api/clients/${clientId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClient(client?.filter((user) => user.id !== clientId));
      toast.success("User deleted successfully!");
      setSelectedRecord({
        id: "",
        modal: false,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
      } else {
        console.error("Error deleting user:", error);
        toast.error("Error deleting user");
      }
    }
    setIsLoading(false); // End loading
  };

  const handleUpdateClientAPI = async () => {
    const token = localStorage.getItem("token");

    const updatedUserData = {
      name: newUser.name,
      user_id: Number(newUser.id),
      email: newUser.email,
      phone: Number(newUser.phone),
      address: newUser.address,
      status: newUser.status === "Active" ? "1" : "0",
    };
    try {
      const response = await axiosInstance.put(
        `/api/clients/${newUser.id}`,
        updatedUserData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsEditing(false);
      toast.success("client updated successfully");
      handleClose();
      fetchClient();

      if (response.status === 201) {
        setIsEditing(false);
        setEditedStatus("");
      } else {
        console.error("Error updating user:", response);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("please try again ");
    }
  };

  const handleCreateClientAPI = async (event) => {
    const { tiktok, ...rest } = newUser;
    const { name, phone, address } = rest;

    // Check if any of the fields are empty
    if (!name.trim() || !phone.trim() || !address.trim()) {
      toast.error("Please fill in all the fields before submitting.");
      return; // Prevent the API call
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const clientPayload = {
      ...rest,
      phone: Number(phone),
    };
    try {
      await axiosInstance.post(`/api/clients`, clientPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      handleClose();
      fetchClient();
      toast.success("Client created successfully");
    } catch (error) {
      setIsLoading(false); // Ensure loading is set to false in case of error
      toast.error(error.response.data.message);
      console.error("Error creating user:", error);
    }
  };

  const filteredClients = client?.filter((client) =>
    client.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const addTiktokAccountsAPICall = async () => {
    const { tiktok } = newUser;

    if (!tiktok) {
      toast.error("Please fill in the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      client_id: view?.clientId,
      tiktok,
    };

    try {
      if (edit) {
        await axiosInstance.put(
          `/api/tiktoks/${edit.id}`,
          {
            ...payload,
            status: editedStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await axiosInstance.post(`/api/tiktoks`, payload, {});
      }
      setIsLoading(false);
      setEdit("");
      setEditedStatus("");
      setNewUser((prevState) => ({
        ...prevState,
        tiktok: "",
      }));

      getTiktoks(view?.clientId);
      toast.success(`${edit ? "Updated" : "Created"} successfully`);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
      console.error("Error while creating/updating:", error);
    }
  };

  const socialMediaSettings = () => {
    return (
      <Form>
        <Form.Group
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <Form.Label htmlFor="tiktok">Tiktok</Form.Label>
          <Form.Check
            type="switch"
            id="tiktok"
            checked={settings?.tiktok}
            onClick={(e) => updateStatus(e)}
          />
        </Form.Group>

        <Form.Group
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "auto",
          }}
        >
          <Form.Label htmlFor="instagram">Instagram</Form.Label>
          <Form.Check
            type="switch"
            id="instagram"
            checked={settings?.instagram}
            onClick={(e) => updateStatus(e)}
          />
        </Form.Group>
      </Form>
    );
  };

  const clientFormUI = () => {
    return (
      <Form>
        <Form.Group controlId="exampleForm.ControlInput1" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter Your Name "
            autoFocus
            className="creatuserListing"
            value={newUser.name}
            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
            required
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail" className="mb-3">
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
        </Form.Group>

        {!editedStatus && (
          <Form.Group controlId="formBasicPassword" className="mb-3">
            <Form.Control
              type="password"
              placeholder="Enter password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
            />
          </Form.Group>
        )}

        <Form.Group controlId="exampleForm.ControlInput2" className="mb-3">
          <Form.Control
            type="number"
            placeholder="Mobile number"
            autoFocus
            className="creatuserListing"
            value={newUser.phone}
            onChange={(e) => setNewUser({ ...newUser, phone: e.target.value })}
            required
          />
        </Form.Group>

        <Form.Group controlId="exampleForm.ControlInput4" className="mb-3">
          <Form.Control
            type="text"
            autoFocus
            value={newUser.address}
            onChange={(e) =>
              setNewUser({ ...newUser, address: e.target.value })
            }
            required
            placeholder="Address"
          />
        </Form.Group>
      </Form>
    );
  };

  const addEditTiktokAccounts = () => {
    return (
      <div class="mt-3 ">
        <Form.Group
          className="addToMoreList mb-3"
          controlId="exampleForm.ControlInput3"
        >
          <Form.Control
            type="text"
            autoFocus
            style={{ display: "inline-block" }}
            value={newUser.tiktok}
            onChange={(e) =>
              setNewUser((prevState) => ({
                ...prevState,
                tiktok: e.target.value,
              }))
            }
            required
            placeholder="Tiktok@id"
            disabled={edit?.tiktok}
          />
        </Form.Group>

        {editedStatus && (
          <div class="d-flex justify-content-around">
            <select
              className="mr-2"
              style={{
                padding: "10px",
                backgroundColor: "white",
                border: "none",
              }}
              value={editedStatus}
              onChange={(e) => {
                setEditedStatus(e.target.value);
              }}
            >
              <option value="1">Active</option>
              <option value="0">In-Active</option>
            </select>
            <button
              class="btn btn-danger"
              onClick={() => {
                setEditedStatus(false);
                setNewUser((prevState) => ({
                  ...prevState,
                  tiktok: "",
                }));
                setEdit("");
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className=" clientLists userLists">
      <CommonHeader
        dashboardName={"Client"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => {
          setShow(true);
          setAddEditModal(true);
        }}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">
            <table className="user-table mt-4">
              <thead>
                <tr>
                  {clientHeadings.map((heading) => (
                    <th
                      className={`${heading === "Action" ? "text-center" : ""}`}
                    >
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredClients.length > 0 ? (
                  filteredClients.map((client, index) => (
                    <tr
                      key={client.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{client.name}</td>
                      <td>{client.email}</td>
                      <td>{client.phone}</td>
                      <td>{client.address}</td>
                      <td>{client.created_by}</td>

                      <td>{client.tiktok ? "ON" : "OFF"}</td>
                      <td>{client.instgram ? "ON" : "OFF"}</td>
                      <td>
                        <button
                          data-tooltip-id="settings-button"
                          className="edit me-2"
                          onClick={() => handleSettings(client)}
                          style={{
                            padding: "4px 10px",
                            fontSize: "22px",
                          }}
                        >
                          <CiSettings />
                        </button>
                        <ReactTooltip
                          id="settings-button"
                          place="bottom"
                          content="Toggle Tiktok/Instagram"
                        />
                      </td>

                      <td>
                        {client.name === "Super Admin" ? (
                          <span className="fs-6">
                            Admin user is not Viewable
                          </span>
                        ) : (
                          <div className="d-flex ">
                            {/* view tiktok accounts */}
                            <button
                              data-tooltip-id="add-accounts"
                              className="edit me-2"
                              onClick={() => handleView(client)}
                              style={{
                                padding: "4px 10px",
                                fontSize: "22px",
                              }}
                            >
                              <FaUserPlus />
                            </button>
                            <ReactTooltip
                              id="add-accounts"
                              place="bottom"
                              content="Add Account"
                            />

                            {hasPermission(
                              user.permissions,
                              "update-client"
                            ) && (
                                <button
                                  data-tooltip-id="Edit-button"
                                  className="edit me-2"
                                  onClick={() => handleEdit(client)}
                                  style={{
                                    padding: "4px 10px",
                                    fontSize: "22px",
                                  }}
                                >
                                  <TbEdit />
                                </button>
                              )}
                            <ReactTooltip
                              id="Edit-button"
                              place="bottom"
                              content="Edit Client"
                            />
                            <Toaster position="top-center" richColors />

                            {hasPermission(
                              user.permissions,
                              "delete-client"
                            ) && (
                                <button
                                  data-tooltip-id="delete-button"
                                  className="delete edit"
                                  style={{
                                    background: "red",
                                    color: "white",
                                    fontSize: "22px",
                                    padding: "4px 10px",
                                  }}
                                  onClick={() => {
                                    setSelectedRecord({
                                      id: client.id,
                                      modal: true,
                                    });
                                  }}
                                >
                                  <MdDelete />
                                </button>
                              )}
                            <ReactTooltip
                              id="delete-button"
                              place="bottom"
                              content="Delete Client"
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      <NoDataFoundPage />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div>
            {totalPages > 1 && (
              <div className="pagination">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  pageStart={pageStart}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Delete Modal */}
      <DeleteModal
        toggleState={selectedRecord?.modal}
        toggleHandler={() => setSelectedRecord(false)}
        apiCallHandleronClick={() => handleDeleteAPICall(selectedRecord?.id)}
      />

      {/* Add / Edit / View Modal */}
      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          view?.visible ? (
            <h4 className="create_user_heading">Tiktok Accounts</h4>
          ) : settings?.visible ? (
            <h4 className="create_user_heading"> Settings </h4>
          ) : (
            addEditModal && (
              <div>
                <h4 className="create_user_heading">Manage Client</h4>
                <p className=" fs-5 mt-3 text-muted">
                  Client to manage and view your profile
                </p>
              </div>
            )
          )
        }
        bodyContent={
          view.visible ? (
            <>
              {addEditTiktokAccounts()}
              {list.length > 0 && (
                <table
                  class="user-table mt-3"
                  style={{
                    height: "auto",
                    maxHeight: "30vh",
                    overflowX: "auto",
                  }}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.map((record, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <Link to={`/Tiktok-statistics/${record?.id}`}>
                            {record.tiktok}
                          </Link>
                        </td>

                        <td>{Number(record.status) ? "Active" : "InActive"}</td>

                        <td>
                          <button
                            class="btn btn-primary btn-sm me-2"
                            onClick={(e) =>
                              record.deleted_at
                                ? handleRestoreTiktok(e, record)
                                : handleEditList(e, record)
                            }
                            disabled={edit?.tiktok}
                          >
                            {record.deleted_at ? <TbRestore /> : <TbEdit />}
                          </button>
                          {/* TODO: */}
                          <button
                            class="btn btn-danger btn-sm ml-2"
                            onClick={(e) => handleDeleteList(e, record)}
                            disabled={record?.deleted_at ? "disabled" : ""}
                          >
                            <MdDelete />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </>
          ) : settings?.visible ? (
            socialMediaSettings()
          ) : addEditModal ? (
            clientFormUI()
          ) : (
            ""
          )
        }
        footerContent={
          <>
            {view.visible ? (
              <Button
                variant="primary"
                className="creatrUserSubmit"
                onClick={addTiktokAccountsAPICall}
              >
                {edit?.tiktok ? `Update` : `Add`} Account
              </Button>
            ) : addEditModal ? (
              <Button
                variant="primary"
                className="creatrUserSubmit"
                onClick={
                  editedStatus ? handleUpdateClientAPI : handleCreateClientAPI
                }
                disabled={isLoading}
              >
                {editedStatus ? "Update" : "Create"} Client
              </Button>
            ) : (
              ""
            )}
            <Button
              variant="secondary"
              className="creatrUserClose"
              onClick={handleClose}
            >
              Close
            </Button>
          </>
        }
      />
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default ClientListing;
