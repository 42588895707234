export const clientHeadings = [
    "S.No",
    "Name",
    "Email",
    "Phone",
    "Address",
    "Created By",
    "Tiktok",
    "Instagram",
    "Settings",
    "Actions",
  ],
  userVideoDetails = [
    "Video ID",
    "Sound ID",
    "Video Url",
    "Views",
    "Likes",
    "Shares",
    "Saved",
    "Comments",
    "Video Create Date",
    "Details",
    "Tag",
  ],
  videoDetails = [
    "views",
    "likes",
    "comments",
    "shares",
    "saved",
    "created_at",
    "updated_at",
  ],
  soundDetailsHeadings = [
    "id",
    "User Count",
    "Sound ID",
    "Created At",
    "Updated At",
  ],
  roles = ["super-admin", "admin", "client", "freelancer", "account-manager"],
  colors = {
    pink: "#ff5353", // likes
    blue: "#0889e5", // views
    light_blue: "#02b3ff",
    line_blue: "#3d93b4",
    sea_green: "#00c5b3", // comments
    yellow: "#ffa230", // shares
    light_yellow: "#FFCE56", // shares border
    purple: "#9966FF", // saved
    sky: "#CCCCFF",
    red: "#FF0000",
    black: "#000000",
    gray: "#808080",
    silvar: "#C0C0C0",
    white: "#ffffff",
    red: "#FF0000",
  },
  backgroundColors = {
    light_pink: "#FF99A6",
    light_blue: "#6CBDF0",
    light_sea_green: "#80D6D6",
    light_yellow: "#FFB763",
    lighter_yellow: "#FFDB8D",
    light_purple: "#B399FF",
    light_sky: "#cce0ff",
    shape_blue: "#6f8090",
    chart_cards: "#7c7386",
    red: "#FF474C",
  },
  digitsColor = {
    blue: "#80a7ce",
    green: "#52bc62",
    purple: "#8779c8",
    red: "#c47a7b",
  };
