import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";
import { hasPermission } from "../../../helperPermissionEditor";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import CustomModal from "../common-components/customModal";
import { LuEye } from "react-icons/lu";
import { GrUserManager } from "react-icons/gr";
import { ImAttachment } from "react-icons/im";
import { MultiSelect } from "react-multi-select-component";
import DeleteModal from "../common-components/deleteModal";
import NoDataFoundPage from "../common-components/NoDataFound";

const AccountManager = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [accountManagers, setAccountManagers] = useState([]),
    [isEditing, setIsEditing] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [selectedOptions, setSelectedOptions] = useState([]),
    [selectedAccountManager, setSelectedAccountManager] = useState(""),
    [record, setRecord] = useState({
      name: "",
      email: "",
      password: "",
      status: "",
      phoneno: "",
      address: "",
      clientId: "",
    }),
    [view, setView] = useState({
      visible: false,
    }),
    [options, setOptions] = useState({
      status: [],
      clients: [],
      freelancers: [],
    }),
    [show, setShow] = useState(false),
    [type, setType] = useState({
      user: "",
      visible: false,
      data: [],
    }),
    [allOptoins, setAllOptions] = useState({
      clients: [],
      freelancers: [],
    }),
    [selectedRecord, setSelectedRecord] = useState({
      id: "",
      modal: false,
    }),
    [deleteClient, setDeleteClient] = useState(false),
    [selectedClientID, setSelectedClientID] = useState(""),
    [deleteFreelancer, setDeleteFreelancer] = useState(false),
    [selectedFreelancerID, setSelectedFreelancerID] = useState("");

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setRecord({
      name: "",
      email: "",
      status: "",
      phone: "",
      address: "",
    });
    setIsEditing("");
    setView({});
    setType({
      user: "",
      visible: false,
      data: [],
    });
    setSelectedAccountManager("");
    setSelectedOptions([]);
  };

  const getListing = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/accountManagers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setAccountManagers(response.data.accountManagers);
      setTotalPages(response.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const getAttachedClientsOrFreelancers = async (row, userType) => {
    setSelectedAccountManager(row);
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/accountManagers/${row?.id}/${userType}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { clients, freelancers } = response?.data;
      if (response.status === 200) {
        const data = userType === "clients" ? clients : freelancers;

        setType({
          user: userType,
          visible: true,
          data,
        });

        const activeRecord = data
          .filter((r) => r.status === "Active" && r)
          .map((r) => ({
            label: r.name,
            value: r.name,
          }));

        setSelectedOptions(activeRecord);

        setShow(true);
      }
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);

    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  useEffect(() => {
    getListing(currentPage);
    getDropdowns();

    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage]);
  //
  const getDropdowns = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Define the three API calls
      const statusCall = axiosInstance.get(`/api/accountManagers/create`, {
        headers,
      });
      const clientCall = axiosInstance.get(`/api/dashboard/clients`, {
        headers,
      });
      const freelancerCall = axiosInstance.get(`/api/dashboard/freelancers`, {
        headers,
      });

      // Use Promise.allSettled to execute all calls in parallel
      const [statusResult, clientResult, freelancerResult] =
        await Promise.allSettled([statusCall, clientCall, freelancerCall]);

      const updatedOptions = {
        status: [],
        clients: [],
        freelancers: [],
      };

      // Handle the status result
      if (statusResult.status === "fulfilled") {
        const createOptions = Object.values(
          statusResult.value.data[`status-dropdown`]
        );
        updatedOptions.status = [
          { id: 0, name: createOptions[0] },
          { id: 1, name: createOptions[1] },
        ];
      } else {
        console.error("Error fetching status data:", statusResult.reason);
        toast.error("Failed to load status dropdown.");
      }

      // Handle the client result
      if (clientResult.status === "fulfilled") {
        updatedOptions.clients = clientResult.value.data.clients.map(
          (client) => ({
            label: client.name,
            value: client.name,
          })
        );
      } else {
        console.error("Error fetching clients data:", clientResult.reason);
        toast.error("Failed to load clients dropdown.");
      }

      // Handle the freelancer result
      if (freelancerResult.status === "fulfilled") {
        updatedOptions.freelancers = freelancerResult.value.data.clients.map(
          (freelancer) => ({
            label: freelancer.name,
            value: freelancer.name,
          })
        );
      } else {
        console.error(
          "Error fetching freelancer data:",
          freelancerResult.reason
        );
        toast.error("Failed to load freelancer dropdown.");
      }

      if (
        clientResult.status === "fulfilled" &&
        freelancerResult.status === "fulfilled"
      ) {
        // storing all option into a seperate state for update case
        setAllOptions({
          clients: clientResult.value.data.clients,
          freelancers: freelancerResult.value.data.clients,
        });
      }
      // Set the updated options in state
      setOptions(updatedOptions);
    } catch (error) {
      console.error("Error during API calls:", error);
      toast.error("Failed to load dropdowns. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (row) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.get(
        `/api/accountManagers/${row?.id}/edit`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      setShow(true);
      if (response.status === 200) {
        const { name, phone, address, status, email, id } =
          response.data.accountManager;
        setIsEditing(response.data.accountManager);

        setRecord((prevState) => ({
          ...prevState,
          name,
          email,
          address,
          status: status === "Active" ? "1" : "0",
          phoneno: phone,
        }));
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching record for edit:", error);
    }
  };

  const handleDeleteAPICall = async (id) => {
    const token = localStorage.getItem("token");

    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/accountManagers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAccountManagers(accountManagers.filter((sound) => sound.id !== id));

      toast.success("Record deleted successfully!");
      setSelectedRecord({
        id: "",
        modal: false,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
      } else {
        console.error("Error deleting Record:", error);
        toast.error("Error deleting Record");
      }
    }
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");
    const { name, email, status, password, phoneno, address } = record;
    const updatedData = {
      name,
      email,
      password,
      phone: phoneno,
      status,
      address,
    };

    try {
      const response = await axiosInstance.put(
        `/api/accountManagers/${isEditing.id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleClose();
      toast.success("Record updated successfully");
      getListing();

      if (response.status === 200) {
        setIsEditing("");
      } else {
        console.error("Error while updating:", response);
      }
    } catch (error) {
      console.error("Error while updating:", error);
      toast.error(error.response.data.message || "Please try again");
    }
  };

  const handleCreate = async () => {
    const { name, email, password, phoneno, address } = record;
    // Validate the form fields
    if (!name || !email || !phoneno || !password || !address) {
      toast.error("Please fill in all the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      name,
      email,
      password,
      address,
      phone: phoneno,
    };

    try {
      await axiosInstance.post(`/api/accountManagers`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      getListing();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating record");
      console.error("Error creating record:", error);
    }
  };

  const handleAttachment = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    const allItems = selectedOptions;

    let payload = {};
    if (type.user === "clients") {
      const intersection = allOptoins.clients
        .filter((record) =>
          allItems.find((selected) => selected.label === record.name)
        )
        .map((r) => r.id);

      const madeItUnique = Array.from(new Set(intersection));
      payload = {
        account_manager_id: selectedAccountManager?.id,
        clients: madeItUnique,
      };
    } else if (type.user === "freelancers") {
      const intersection = allOptoins.freelancers
        .filter((record) =>
          allItems.find((selected) => selected.label === record.name)
        )
        .map((r) => r.id);

      const madeItUnique = Array.from(new Set(intersection));
      payload = {
        account_manager_id: selectedAccountManager?.id,
        freelancers: madeItUnique,
      };
    }

    try {
      await axiosInstance.post(
        `/api/accountManagers/attach-${type?.user}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsLoading(false);
      getListing();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating record");
      console.error("Error creating record:", error);
    }
  };

  const handleClientDeattach = async (accountManagerClient) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      await axiosInstance.post(
        `/api/accountManagers/deattach-client`,
        {
          account_manager_client: accountManagerClient,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Client detached successfully!");
      setDeleteClient(false);
      setShow(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error detaching client");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFreelancerDeattach = async (accountManagerFreelancer) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      await axiosInstance.post(
        `/api/accountManagers/deattach-freelancer`,
        {
          account_manager_freelancer: accountManagerFreelancer,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Client detached successfully!");
      setDeleteFreelancer(false);
      setShow(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error detaching client");
    } finally {
      setIsLoading(false);
    }
  };

  const handleView = (record) => {
    const {
      name,
      email,
      phone,
      address,
      status,
      client,
      updated_at,
      created_at,
    } = record;
    setView({
      visible: true,
      name,
      email,
      phone,
      address,
      status,
      client,
      updated_at,
      created_at,
    });
    setShow(true);
  };

  const tableActionsUI = (row) => {
    return (
      <>
        <td>
          <Toaster position="top-center" richColors />
          <button
            data-tooltip-id="attach-client"
            className="edit me-2"
            onClick={() => getAttachedClientsOrFreelancers(row, "clients")}
            style={{
              padding: "4px 10px",
              fontSize: "22px",
            }}
            disabled={!hasPermission(user?.permissions, "view-client")}
          >
            <ImAttachment />
          </button>
          <ReactTooltip
            id="attach-client"
            place="bottom"
            content="Attach Clients"
          />
        </td>

        <td>
          <Toaster position="top-center" richColors />
          <button
            data-tooltip-id="attach-freelancer"
            className="edit me-2"
            onClick={() => getAttachedClientsOrFreelancers(row, "freelancers")}
            style={{
              padding: "4px 10px",
              fontSize: "22px",
            }}
            disabled={!hasPermission(user?.permissions, "view-freelancer")}
          >
            <GrUserManager />
          </button>
          <ReactTooltip
            id="attach-freelancer"
            place="bottom"
            content="Attach freelancer"
          />
        </td>

        <td className="d-flex align-items-center justify-content-center">
          <Toaster position="top-center" richColors />
          <button
            data-tooltip-id="view-button"
            className="edit me-2"
            onClick={() => handleView(row)}
            style={{
              padding: "4px 10px",
              fontSize: "22px",
            }}
            disabled={!hasPermission(user?.permissions, "view-account-manager")}
          >
            <LuEye />
          </button>
          <ReactTooltip
            id="view-button"
            place="bottom"
            content="View Details"
          />

          <span className="px-2 edit me-2" style={{ cursor: "pointer" }}>
            <TbEdit
              size={24}
              data-tooltip-id="editTooltip"
              data-tooltip-content="Edit"
              onClick={() => handleEdit(row)}
              disabled={
                !hasPermission(user?.permissions, "update-account-manager")
              }
            />
            <ReactTooltip id="editTooltip" />
          </span>
          <span
            className="px-2"
            style={{
              cursor: "pointer",
              background: "red",
              color: "white",
              fontSize: "16px",
              padding: "8px 10px",
              borderRadius: "8px",
            }}
          >
            <MdDelete
              size={24}
              data-tooltip-id="deleteTooltip"
              data-tooltip-content="Delete"
              onClick={() => {
                setSelectedRecord({
                  id: row.id,
                  modal: true,
                });
              }}
              disabled={
                !hasPermission(user?.permissions, "delete-account-manager")
              }
            />
            <ReactTooltip id="deleteTooltip" />
          </span>
        </td>
      </>
    );
  };

  const renderTable = (content, id = true) => {
    return (
      <>
        <thead>
          <tr>
            {id && <th>ID</th>}
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Created By</th>
            <th>Status</th>
            <th>Created At</th>
            <th>Updated At</th>
            {id && (
              <>
                <th>Attach Clients</th>
                <th>Attach Freelancers</th>
                <th className="text-center">Actions</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {content?.length > 0 ? (
            content?.map((row, i) => (
              <tr key={row.id}>
                {id && <td>{i + 1}</td>}
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.phone}</td>
                <td>{row.created_by}</td>
                <td>{row.status}</td>
                <td>{new Date(row.created_at).toLocaleDateString()}</td>
                <td>{new Date(row.updated_at).toLocaleDateString()}</td>
                {id && tableActionsUI(row)}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="11" className="text-center">
                <NoDataFoundPage />
              </td>
            </tr>
          )}
        </tbody>
      </>
    );
  };

  const getTableUI = () => {
    return (
      <table className="user-table mt-4">{renderTable(accountManagers)}</table>
    );
  };
  const handleChange = (value) => {
    if (value?.length < 1) {
      setSelectedOptions([]);
      return;
    }
    setSelectedOptions(value);
  };

  const showListing = () => {
    return (
      <>
        <table class="user-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {type?.data?.length > 0 ? (
              type?.data?.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.status}</td>
                  <td>
                    <button
                      data-tooltip-id="Delete-button"
                      className="delete edit"
                      style={{
                        background: "red",
                        color: "white",
                        fontSize: "22px",
                        padding: "4px 10px",
                      }}
                      onClick={() => {
                        if (type?.user === "clients") {
                          setDeleteClient(true);
                          setSelectedClientID(row.id);
                        } else if (type?.user === "freelancers") {
                          setDeleteFreelancer(true);
                          setSelectedFreelancerID(row.id);
                        }
                      }}
                      disabled={record?.deleted_at ? 'disabled' : ''}
                    >
                      <MdDelete />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  No Records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <h5> Select {type?.user} to Attach</h5>

        <MultiSelect
          options={
            type?.user === "clients" ? options?.clients : options?.freelancers
          }
          value={selectedOptions}
          onChange={handleChange}
          labelledBy="Select"
          className="multi-select-container"
        />
      </>
    );
  };

  const getFormUI = () => {
    return view?.visible ? (
      <table class="user-table">{renderTable([view], false)}</table>
    ) : type?.visible ? (
      showListing()
    ) : (
      <div style={{ justifyContent: "center !important" }}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Control
            type="text"
            placeholder="Name"
            value={record.name}
            onChange={(e) => setRecord({ ...record, name: e.target.value })}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formText">
          <Form.Control
            type="text"
            placeholder="Email"
            value={record.email}
            onChange={(e) => setRecord({ ...record, email: e.target.value })}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formDescription">
          <Form.Control
            type="number"
            placeholder="Phone Number"
            value={record.phoneno}
            onChange={(e) => setRecord({ ...record, phoneno: e.target.value })}
          />
        </Form.Group>

        {/* only show in creating */}
        {!isEditing && (
          <Form.Group className="mb-3" controlId="formText">
            <Form.Control
              type="password"
              placeholder="Password"
              value={record.password}
              onChange={(e) =>
                setRecord({ ...record, password: e.target.value })
              }
            />
          </Form.Group>
        )}

        <Form.Group className="mb-3" controlId="formAddress">
          <Form.Control
            type="text"
            placeholder="Address"
            value={record.address}
            onChange={(e) => setRecord({ ...record, address: e.target.value })}
          />
        </Form.Group>

        {isEditing && (
          <Form.Group className="mb-3" controlId="formClientStatus">
            <Form.Select
              value={record.status}
              onChange={(e) =>
                setRecord({
                  ...record,
                  status: e.target.value,
                })
              }
            >
              <option>Status</option>
              {options.status.map((status, i) => (
                <option key={i} value={status.id}>
                  {status.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        )}
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Account Manager"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => setShow(true)}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
          {totalPages > 1 && (
            <div className="pagination">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                pageStart={pageStart}
              />
            </div>
          )}
        </div>
      </div>
      {/* Delete Modal */}
      <DeleteModal
        toggleState={selectedRecord?.modal}
        toggleHandler={() => setSelectedRecord(false)}
        apiCallHandleronClick={() => handleDeleteAPICall(selectedRecord?.id)}
      />
      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div>
            <h2>
              {view?.visible
                ? `Account Managers List`
                : type?.visible
                  ? `Attached ${type.user}`
                  : `${isEditing ? "Update" : "Create"} Account Manager`}
            </h2>
          </div>
        }
        bodyContent={getFormUI()}
        footerContent={
          <>
            <div className="d-flex justify-content-center align-items-center">
              {type?.visible ? (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={handleAttachment}
                >
                  {`Attach ${type?.user}`}
                </Button>
              ) : !view?.visible ? (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={isEditing ? handleUpdate : handleCreate}
                  disabled={isLoading}
                >
                  {`${isEditing ? "Update" : "Create"} Account Manager`}
                </Button>
              ) : (
                ""
              )}
              <Button
                variant="secondary"
                className="creatrUserClose"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
      {/* Delete Confirmation Modal */}
      <CustomModal
        show={type?.user === "clients" ? deleteClient : deleteFreelancer}
        handleClose={() => {
          if (type?.user === "clients") {
            setDeleteClient(false);
          } else if (type?.user === "freelancers") {
            setDeleteFreelancer(false);
          }
        }}
        headerContent={<h4>Confirm Delete</h4>}
        bodyContent={
          <p>
            Are you sure you want to detach this{" "}
            {type?.user === "clients" ? "client" : "freelancer"}?
          </p>
        }
        footerContent={
          <>
            <Button
              variant="danger"
              onClick={() =>
                type?.user === "clients"
                  ? handleClientDeattach(selectedClientID)
                  : handleFreelancerDeattach(selectedFreelancerID)
              }
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Confirm"}
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                if (type?.user === "clients") {
                  setDeleteClient(false);
                } else if (type?.user === "freelancers") {
                  setDeleteFreelancer(false);
                }
              }}
            >
              Close
            </Button>
          </>
        }
      />
    </div>
  );
};

export default AccountManager;
