import React from "react";
import "../commoncomponent.css";

import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";

const PaginationComponent = ({
  currentPage,
  totalPages,
  handlePageChange,
  pageStart,
}) => {
  const renderPageButtons = () => {
    const pageButtons = [];
    const endPage = Math.min(pageStart + 4, totalPages);

    for (let i = pageStart; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  const handlePrev = () => {
    if (pageStart > 1) {
      handlePageChange(pageStart - 5); // Adjusted to go back by 5 pages
    }
  };

  const handleNext = () => {
    if (pageStart + 5 <= totalPages) {
      handlePageChange(pageStart + 5);
    }
  };

  if (totalPages <= 1) return null;

  return (
    <div className="pagination">
      {pageStart > 1 && (
        <button onClick={handlePrev}>
          <GrFormPreviousLink /> Prev ({pageStart - 5})
        </button>
      )}
      {renderPageButtons()}
      {pageStart + 5 <= totalPages && (
        <button onClick={handleNext}>
          Next ({pageStart + 5}) <GrFormNextLink />
        </button>
      )}
    </div>
  );
};

export default PaginationComponent;
