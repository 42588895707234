import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const NoDataFoundPage = () => {
  return (
    <Container className="text-center mt-5">
      <Row>
        <Col>
          <h1 className="display-4">No Data Found</h1>
          <p className="lead">We couldn't find any data to display.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default NoDataFoundPage;
