import React, { useEffect, useState, useContext } from "react";
import { Bar, Line } from "react-chartjs-2";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import SkeletonLoader from "../../common-components/skeletonLoader";
import ErrorPage from "../../common-components/error";
import subDays from "date-fns/subDays";
import DaysFilter from "./DaysFilter";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LimitFilter from "./LimitFilter";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { backgroundColors, colors } from "../../../../utils/constants";
import FullscreenWrapper from "../../common-components/FullScreenWrapper";
import NoDataFoundPage from "../../common-components/NoDataFound";
import { LoginContext } from "../../../../App";
import { getColor } from "../../../../utils/helperfunctions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TopTrendingVideos = () => {
  const [videoData, setVideoData] = useState({
      views: [],
      interactions: [],
      labels: [],
    }),
    { theme, fullScreen } = useContext(LoginContext),
    [selectedDays, setSelectedDays] = useState([
      subDays(new Date(), 30 - 1),
      new Date(),
    ]),
    [selectedLimit, setSelectedLimit] = useState(10),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    [noData, setNoData] = useState(null);

  const fetchTopVideos = async () => {
    const token = localStorage.getItem("token");
    setLoading(true);
    const from = moment(selectedDays[0]).format("YYYY-MM-DD");
    const to = moment(selectedDays[1]).format("YYYY-MM-DD");

    try {
      const response = await axiosInstance.post(
        "/api/dashboard/top-trending-videos",
        { days: null, from, to, limit: selectedLimit },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response.data.data;
      const localLabel = [],
        localViews = [],
        localInteractions = [];

      data?.forEach((data) => {
        localLabel.push(data.video_id);
        localViews.push(data.views);
        localInteractions.push(data.interactions);
      });
      setVideoData({
        labels: localLabel,
        views: localViews,
        interactions: localInteractions,
      });

      if (!data.length) {
        setNoData(true);
      } else {
        setNoData(false);
      }

      setLoading(false);
    } catch (err) {
      if (err.response.status === 403 || err.response.status === 404) {
        setNoData(true);
      }
      setError("Failed to fetch top trending videos.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopVideos();
  }, [selectedDays, selectedLimit]);

  const generateChartData = () => {
    const labels = videoData.labels.map((video) => {
      const truncatedStr = `${video.slice(0, 2)}...${video.slice(-2)}`;
      return truncatedStr;
    });
    const data = {
      labels,
      datasets: [
        {
          label: "Views",
          data: videoData.views,
          backgroundColor: colors.blue,
          borderColor: colors.blue,
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: colors.blue,
        },
        {
          label: "Interactions",
          data: videoData.interactions,
          backgroundColor: backgroundColors.lighter_yellow,
          borderColor: colors.light_yellow,
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: colors.purple,
        },
      ],
    };
    return data;
  };

  const handleChartClick = (event, elements) => {
    if (elements.length > 0) {
      const index = elements[0].index;
      const videoId = videoData[index].video_id;
      window.location.href = `/Tiktok-video-statistics/${videoId}`;
    }
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };
  const onChangeLimit = (value) => {
    setSelectedLimit(value);
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          color: theme === "dark" || fullScreen ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: theme === "dark" || fullScreen ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          label: function (context) {
            const fullSoundId = videoData.labels[context.dataIndex];
            const value = context.raw;
            return `${fullSoundId}: ${value} users`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          color: theme === "dark" || fullScreen ? "#ffffff" : "#000000",
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
      },
    },
    onClick: (event, elements) => handleChartClick(event, elements),
  };

  return (
    <div>
      <div className="filter-container p-3">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <DaysFilter selectedDays={selectedDays} onChange={onChange} />
          </div>
          <div class="col-md-6 col-lg-6 limit-filter">
            <LimitFilter
              selectedLimit={selectedLimit}
              onChange={onChangeLimit}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : noData ? (
        <NoDataFoundPage />
      ) : error ? (
        <ErrorPage refetch={fetchTopVideos} />
      ) : (
        <div className="chart-wrapper" id="trending-videos-1">
          <Bar data={generateChartData()} options={options} />
        </div>
      )}
    </div>
  );
};

export default TopTrendingVideos;
