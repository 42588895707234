import React, { useEffect, useState, useContext } from "react";
import { Line } from "react-chartjs-2";
import CommonHeader from "../common-components/CommonHeader";
import CommonLoader from "../../../loader/CommonLoader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { Link, useParams } from "react-router-dom";
import DaysFilter from "./component/DaysFilter";
import moment from "moment";
import subDays from "date-fns/subDays";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import MetrixCards from "./userListingMetrix/MetrixCards";
import { SlUserFollowing } from "react-icons/sl";
import { GiShadowFollower } from "react-icons/gi";
import { MdFavorite } from "react-icons/md";
import { BiSolidVideos } from "react-icons/bi";
import { colors } from "../../../utils/constants";
import SimpleCards from "../common-components/simpleCards";
import { PiVideoConferenceThin } from "react-icons/pi";
import FullscreenWrapper from "../common-components/FullScreenWrapper";
import { FcStatistics } from "react-icons/fc";
import { LoginContext } from "../../../App";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TikTokUserStatistics = () => {
  const { tiktokId } = useParams(),
    [selectedDays, setSelectedDays] = useState([
      subDays(new Date(), 30 - 1),
      new Date(),
    ]),
    [userStatistics, setUserStatistics] = useState([]),
    [userDetails, setUserDetails] = useState([]),
    [loading, setLoading] = useState(true),
    [error, setError] = useState(null),
    [stats, setStats] = useState({
      views: {
        label: [],
        value: [],
      },
      integrations: {
        label: [],
        value: [],
      },
      engatements: {
        label: [],
        value: [],
      },
    }),
    { theme } = useContext(LoginContext),
    [selectedData, setSelectedData] = useState("user_statistics");

  useEffect(() => {
    const fetchUserStatistics = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      const from = moment(selectedDays[0]).format("YYYY-MM-DD");
      const to = moment(selectedDays[1]).format("YYYY-MM-DD");
      try {
        const response = await axiosInstance.post(
          "/api/tiktok/tiktok-statistics",
          {
            tiktok_id: tiktokId,
            days: null,
            from,
            to,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const labelViews = [],
          valueViews = [],
          labelInteractions = [],
          valueInteractions = [],
          labelEngagement = [],
          valueEngagement = [];
        const {
          daily_average_views,
          daily_average_interactions,
          daily_average_engagement,
          statistics,
          user_details,
        } = response.data;

        daily_average_views.forEach((i) => {
          labelViews.push(moment(i.date).format("DD MMM"));
          valueViews.push(i.daily_average_views);
        });

        daily_average_interactions.forEach((i) => {
          labelInteractions.push(moment(i.date).format("DD MMM"));
          valueInteractions.push(i.daily_average_interactions);
        });

        daily_average_engagement.forEach((i) => {
          labelEngagement.push(moment(i.date).format("DD MMM"));
          valueEngagement.push(i.daily_average_engagement);
        });

        setStats({
          views: {
            label: labelViews,
            value: valueViews,
          },
          integrations: {
            label: labelInteractions,
            value: valueInteractions,
          },
          engatements: {
            label: labelEngagement,
            value: valueEngagement,
          },
        });

        setUserStatistics(statistics);
        setUserDetails(user_details);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch TikTok user statistics");
        setLoading(false);
      }
    };

    fetchUserStatistics();
  }, [tiktokId, selectedDays]);

  const generateChartData = () => {
    const labels = userStatistics.map((stat) =>
      moment(stat.recorded_at).format("DD MMM, YY")
    );

    // Map selected data to datasets
    const datasetsMap = {
      visible_videos_count: {
        label: "Visible Videos Count",
        data: userStatistics.map((stat) => stat.visible_videos_count),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: colors.pink,
        borderWidth: 2,
        pointRadius: 3,
        pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
        // yAxisID: "y",
      },
      follower_count: {
        label: "Followers",
        data: userStatistics.map((stat) => stat.follower_count),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: colors.blue,
        borderWidth: 2,
        pointRadius: 3,
        pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
        // yAxisID: "y1",
      },
      following_count: {
        label: "Following",
        data: userStatistics.map((stat) => stat.following_count),
        backgroundColor: "rgba(255, 206, 86, 0.2)",
        borderColor: colors.light_yellow,
        borderWidth: 2,
        pointRadius: 3,
        pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
        // yAxisID: "y1",
      },
      total_favorited: {
        label: "Total Favorited",
        data: userStatistics.map((stat) => stat.total_favorited),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: colors.sea_green,
        borderWidth: 2,
        pointRadius: 3,
        pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
        // yAxisID: "y1",
      },
    };

    return {
      labels,
      datasets: [datasetsMap[selectedData]],
    };
  };

  const onChange = (dates) => {
    if (!dates) setSelectedDays([subDays(new Date(), 30 - 1), new Date()]);
    else setSelectedDays(dates);
  };

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      tooltip: {
        backgroundColor: "#ffffff",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          title: (tooltipItems) => `Date: ${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const label = dataset.label;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        position: "top",
        labels: {
          color: theme === "dark" ? "#ffffff" : "#000000",
          font: {
            size: 16,
          },
        },
      },
      title: {
        display: false,
        text: "",
        font: {
          size: 16,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: theme === "dark" ? "#ffffff" : "#000000",
        },
        grid: {
          display: false,
        },
        type: "linear",
        display: true,
        position: "left",
        beginAtZero: true,
      },
    },
  };

  const returnFollowerChart = () => {
    return userStatistics.length > 0 ? (
      <Line data={generateChartData()} options={options} />
    ) : (
      <p>No user statistics available.</p>
    );
  };

  const generateChartForStatistics = (label, content) => {
    const data = {
      labels: content.label,
      datasets: [
        {
          label: label,
          data: content.value,
          fill: true,
          backgroundColor: colors.gray,
          borderColor: colors.gray,
          borderWidth: 2,
          pointRadius: 3,
          pointBackgroundColor: colors.gray,
        },
      ],
    };
    return data;
  };
  return (
    <div className="container-fluid tiktok-user-statistics-container">
      <CommonHeader
        dashboardName={userDetails.nickname + " - TikTok User Statistics"}
      />
      <p
        style={{
          color: "red",
          fontSize: "12px",
          marginBottom: "15px",
          marginTop: "15px",
          fontWeight: "bold",
        }}
      >
        This page displays the aggregate data for the entire account. Each day,
        we take a snapshot, which is added as a new point on the graphs.
      </p>
      <DaysFilter selectedDays={selectedDays} onChange={onChange} />

      <div className="metrix">
        <SimpleCards
          cards={[
            {
              icon: <FcStatistics size={40} color="#673AB7" />,
              title: "User Statistics",
              amount: "",
              link: "",
              click: () => setSelectedData("user_statistics"),
            },
            {
              icon: <SlUserFollowing size={40} color="#FF5722" />,
              title: "Follower Count",
              amount: userDetails?.follower_count,
              link: "",
              click: () => setSelectedData("follower_count"),
            },
            {
              icon: <GiShadowFollower size={40} color="#9C27B0" />,
              title: "Following Count",
              amount: userDetails?.following_count,
              link: "",
              click: () => setSelectedData("following_count"),
            },
            {
              icon: <MdFavorite size={40} color="#4CAF50" />,
              title: "Total Favorited",
              amount: userDetails?.total_favorited,
              link: "",
              click: () => setSelectedData("total_favorited"),
            },
            {
              icon: <PiVideoConferenceThin size={40} color="#4CAF50" />,
              title: "Visible Video Count",
              amount: userDetails?.visible_videos_count,
              link: "",
              click: () => setSelectedData("visible_videos_count"),
            },
          ]}
        />
      </div>

      <div
        style={{ backgroundColor: "#fff" }}
        className="d-flex rounded-2 align-items-center p-1 mt-4"
      >
        {userDetails?.avatar && (
          <img
            src={userDetails?.avatar}
            alt="avatar"
            className="rounded-circle me-3"
            style={{
              width: "70px",
              height: "70px",
              objectFit: "cover",
            }}
          />
        )}

        {userDetails?.nickname && (
          <h4>
            <a
              href={userDetails?.tiktok_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {userDetails?.nickname}
            </a>
          </h4>
        )}

        <span className="ms-auto">
          <Link
            to={`/Tiktok-UsersDetails/${tiktokId}/videos`}
            style={{ cursor: "pointer", textDecoration: "none" }}
          >
            Add Video Tag
          </Link>
        </span>
      </div>

      {loading ? (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      ) : error ? (
        <div className="error-overlay">
          <p>{error}</p>
        </div>
      ) : (
        <div className="chart-wrapper">
          {selectedData === "user_statistics" ? (
            <div className="row mt-3">
              <div className="col-4">
                <FullscreenWrapper>
                  <Line
                    data={generateChartForStatistics(
                      "Average Views",
                      stats.views
                    )}
                    options={options}
                  />
                </FullscreenWrapper>
              </div>
              <div className="col-4">
                <FullscreenWrapper>
                  <Line
                    data={generateChartForStatistics(
                      "Average Interactions",
                      stats.integrations
                    )}
                    options={options}
                  />
                </FullscreenWrapper>
              </div>
              <div className="col-4">
                <FullscreenWrapper>
                  <Line
                    data={generateChartForStatistics(
                      "Average Engagements",
                      stats.engatements
                    )}
                    options={options}
                  />
                </FullscreenWrapper>
              </div>
            </div>
          ) : (
            returnFollowerChart()
          )}
        </div>
      )}
      <div className="mt-5 mb-5">
        <h4 className="text-center">TikTok User Statistics</h4>
        <table className="user-table mt-4">
          <thead>
            <tr>
              <th>Followers</th>
              <th>Followings</th>
              <th>Total Likes</th>
              <th>Total Videos</th>
              <th>Recorded At</th>
            </tr>
          </thead>
          <tbody>
            {userStatistics.length > 0 ? (
              userStatistics?.map((user, index) => (
                <tr
                  className=""
                  key={user.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                  }}
                >
                  <td>{user.follower_count}</td>
                  <td>{user.following_count}</td>
                  <td>{user.total_favorited}</td>
                  <td>{user.visible_videos_count}</td>
                  <td>{new Date(user.recorded_at).toLocaleDateString()}</td>
                </tr>
              ))
            ) : (
              <p>No user statistics available.</p>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TikTokUserStatistics;
