import React, { useState, useContext } from "react";
import { FaShoppingBag, FaUsers, FaListAlt } from "react-icons/fa";
import "../../pages/userListingMetrix/usermetrix.css";
import { Link } from "react-router-dom";
import { LoginContext } from "../../../../App";
import { adjustFontSize } from "../../../../utils/helperfunctions";

const Cards = ({
  icon,
  title,
  amount,
  link,
  click,
  color,
  isActive,
  onSelect,
}) => {
  const { theme } = useContext(LoginContext);

  const CardContent = (
    <div
      className={`clickable-card ${isActive ? "active-card" : ""}`}
      style={{
        cursor: "pointer",
        border: isActive
          ? `1px solid ${theme === "dark" ? "#fff" : "#000"}`
          : "1px solid transparent",
      }}
      onClick={onSelect}
    >
      <div className="metrixicon">{icon}</div>
      <div className="metrixdetails">
        <h2 style={{ color: `${color} !important`, fontSize: "2rem" }}>
          {amount}
        </h2>
        <h6 class="text-uppercase">{title}</h6>
      </div>
    </div>
  );

  return link ? (
    <Link to={link} style={{ cursor: "pointer", textDecoration: "none" }}>
      {CardContent}
    </Link>
  ) : (
    CardContent
  );
};

const ClickableCards = ({ cards }) => {
  const { theme, activeIndex, setActiveIndex } = useContext(LoginContext);

  const handleSelect = (index) => {
    setActiveIndex(index);
  };

  return (
    <div>
      <div className="clickable-container">
        {cards?.map((metric, index) => (
          <Cards
            key={index}
            {...metric}
            isActive={activeIndex === index}
            onSelect={() => handleSelect(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ClickableCards;
