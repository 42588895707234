import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import "./pages.css";
import CommonLoader from "../../../loader/CommonLoader";
import { Toaster, toast } from "sonner";
import CommonHeader from "../common-components/CommonHeader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { TbEdit } from "react-icons/tb";
import { TbEditOff } from "react-icons/tb";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { hasPermission } from "../../../helperPermissionEditor";
import { LoginContext } from "../../../App";
import CustomModal from "../common-components/customModal";
import DeleteModal from "../common-components/deleteModal";
import NoDataFoundPage from "../common-components/NoDataFound";

const PermissionListing = () => {
  const { user } = useContext(LoginContext),
    [permissions, setPermissions] = useState([]),
    [isEditing, setIsEditing] = useState(null),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [show, setShow] = useState(false),
    [newPermission, setNewPermission] = useState(""),
    [selectedRecord, setSelectedRecord] = useState({
      id: "",
      modal: false,
    });

  const fetchPermissions = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/permissions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPermissions(response.data.permissions);
    } catch (error) {
      console.error("Full error response:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPermissions();
    const intervalId = setInterval(() => {
      fetchPermissions();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const deleteRecordAPICall = async (id) => {
    const token = localStorage.getItem("token");

    setIsLoading(true); // Start loading
    try {
      await axiosInstance.delete(`/api/permissions/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchPermissions();
      toast.success("Record deleted successfully!");
      setSelectedRecord({
        id: "",
        modal: false,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        // navigate("/");
      } else {
        console.error("Error deleting record:", error);
        toast.error("Error deleting record");
      }
    }
    setIsLoading(false); // End loading
  };

  const handleEdit = async (record) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(
        `/api/permissions/${record.id}/edit`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setShow(true);
      setIsLoading(false);
      setNewPermission(response.data.permission.name);
      setIsEditing(response.data.permission);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching permission details:", error);
    }
  };

  const handleUpdatePermission = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await axiosInstance.put(
        `/api/permissions/${isEditing.id}`,
        { id: isEditing.id, name: newPermission },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setIsLoading(false);
      toast.success("permission updated successfully");
      // setPermissions((prevPermissions) =>
      //   prevPermissions.map((perm) =>
      //     perm.id === permissionId ? response.data.permission : perm
      //   )
      // );
      handleClose();
      fetchPermissions();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
      console.error("Error updating permission:", error);
    }
  };

  const handleCreatePermission = async (event) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      await axiosInstance.post(
        `/api/permissions`,
        { name: newPermission },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const response = await axiosInstance.get(`/api/permissions`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setIsLoading(false);
      toast.success("permission created successfully");
      setPermissions(response.data.permissions);
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const handleClose = () => {
    setShow(false);
    setNewPermission("");
    setIsEditing("");
  };

  const filteredPermissions = permissions.filter((permissions) =>
    permissions.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getTableUI = () => {
    return (
      <table className="user-table mt-4">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Permission Name</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredPermissions.length === 0 ? (
            <tr>
              <td colSpan="4" style={{ textAlign: "center" }}>
              <NoDataFoundPage />
              </td>
            </tr>
          ) : (
            filteredPermissions.map((permission, index) => (
              <tr
                className=""
                key={permission.id}
                style={{
                  backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                }}
              >
                <td>{index + 1}</td>
                <td>{permission.name}</td>
                <td>{new Date(permission.created_at).toLocaleString()}</td>
                <td>
                  {permission.name === "super-admin" ? (
                    <>
                      <span className="noedit " data-tooltip-id="noedit-button">
                        <TbEditOff />
                      </span>
                      <ReactTooltip
                        id="noedit-button"
                        place="bottom"
                        content="Admin Permission cannot Edit"
                      />
                    </>
                  ) : (
                    <div className="d-flex ">
                      <Toaster richColors position="top-center" />
                      <button
                        className="edit"
                        data-tooltip-id="Edit-button"
                        onClick={() => handleEdit(permission)}
                        disabled={
                          !hasPermission(user?.permissions, "update-permission")
                        }
                      >
                        <TbEdit />
                      </button>
                      <ReactTooltip
                        id="Edit-button"
                        place="bottom"
                        content="Edit Permission"
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div className=" userLists">
      <CommonHeader
        dashboardName={"Permission"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => setShow(true)}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
        </div>
      </div>

      {/* Delete Modal */}
      <DeleteModal
        toggleState={selectedRecord?.modal}
        toggleHandler={() => setSelectedRecord(false)}
        apiCallHandleronClick={() => deleteRecordAPICall(selectedRecord?.id)}
      />

      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div>
            <h2 className="create_user_heading">{`${
              isEditing ? "Update" : "Create"
            } Permission`}</h2>
          </div>
        }
        bodyContent={
          <div style={{ justifyContent: "center !important" }}>
            <Form.Group className="mb-3" controlId="formFreelancerName">
              <Form.Control
                type="text"
                value={newPermission}
                onChange={(e) => setNewPermission(e.target.value)}
                placeholder="Permission Name"
              />
            </Form.Group>
          </div>
        }
        footerContent={
          <>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="primary"
                className="creatrUserSubmit me-3"
                onClick={
                  isEditing ? handleUpdatePermission : handleCreatePermission
                }
              >
                {`${isEditing ? "Update" : "Create"} Permission`}
              </Button>
              <Button
                variant="secondary"
                className="creatrUserClose"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        }
      />
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default PermissionListing;
