import React, { useEffect, useState, useContext } from "react";
import { LoginContext } from "../../../../App";
import { hasPermission } from "../../../../helperPermissionEditor";
import { MdAccountBox } from "react-icons/md";
import { GrStatusGood } from "react-icons/gr";
import { SiGoogledocs } from "react-icons/si";
import { CgLoadbarSound } from "react-icons/cg";
import { digitsColor } from "../../../../utils/constants";
import ClickableCards from "../ClickableCards";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import CommonLoader from "../../../../loader/CommonLoader";
import { FaTiktok } from "react-icons/fa";

const MainCards = () => {
  const [userDetails, setUserDetails] = useState({
    tiktokAccountCount: 0,
    tiktokVideoCount: 0,
    tiktokSoundCount: 0,
    tiktokExternalSoundCount: 0,
    tiktoksUserCount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const role = localStorage.getItem("userRole");
  const isSuperAdmin = role === "super-admin";
  const isClient = role === "client";
  const { user, setActiveIndex } = useContext(LoginContext);

  const getCounts = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/dashboard/tiles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const {
          tiktokAccountCount,
          tiktokVideoCount,
          tiktokSoundCount,
          tiktokExternalSoundCount,
        } = response.data.stats;
        setUserDetails({
          tiktokAccountCount,
          tiktokVideoCount,
          tiktokSoundCount,
          tiktokExternalSoundCount,
        });
      }
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCounts();

    // remove active class from cards
    const links = document.querySelectorAll(".link");
    links.forEach((link) => {
      link.addEventListener("click", () => {
        const activeCard = document.querySelector(".active-card");
        if (activeCard) {
          setActiveIndex(false);
        }
      });
    });
  }, []);

  const cardsData = () => {
    const cardsToShow = [
      {
        icon: <MdAccountBox size={40} color={digitsColor.blue} />,
        title: "TikTok Analytics",
        amount: userDetails?.tiktokAccountCount,
        link: "/tiktokAccounts",
        color: digitsColor.blue,
      },
      {
        icon: <GrStatusGood size={40} color={digitsColor.green} />,
        title: "TikTok Posts",
        amount: userDetails?.tiktokVideoCount,
        link: "/tiktokVideos",
        color: digitsColor.green,
      },
      {
        icon: <CgLoadbarSound size={40} color={digitsColor.purple} />,
        title: "TikTok Sounds",
        amount: userDetails?.tiktokSoundCount,
        link: "/tiktok-sounds",
        color: digitsColor.purple,
      },
    ];

    if (
      user?.permissions &&
      hasPermission(user.permissions, "view-external-sounds")
    ) {
      cardsToShow.push({
        icon: <SiGoogledocs size={40} color={digitsColor.purple} />,
        title: "Bobby's Favourite Sounds",
        amount: userDetails?.tiktokExternalSoundCount,
        link: "/external-tiktok-sounds",
        color: digitsColor.purple,
      });
    }

    if (isClient) {
      cardsToShow.push({
        icon: <FaTiktok size={40} color={digitsColor.red} />,
        title: "TikTok Account Management",
        amount: userDetails?.tiktokAccountCount,
        link: "/add-tiktoks",
        color: digitsColor.red,
      });
    }

    return cardsToShow;
  };

  return (
    <div className="metrix">
      <ClickableCards cards={cardsData()} />
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
    </div>
  );
};

export default MainCards;
