import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useParams } from "react-router-dom";
import CommonLoader from "../../../../loader/CommonLoader";
import CommonHeader from "../../common-components/CommonHeader";
import axiosInstance from "../../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../../App";

const FreelancerClientsHashtag = () => {
  const { user } = useContext(LoginContext),
    [data, setData] = useState([]),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    location = useLocation(),
    state = location.state,
    { id } = useParams();

  const checkSource = () => {
    if (state.cameFrom === "book") {
      return { book_id: state.id };
    } else if (state.cameFrom === "text") {
      return { text_id: state.id };
    } else {
      return {
        book_id: id,
        text_id: id,
      };
    }
  };

  const getListing = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(
        `/api/dashboard/hashtags`,
        {
          ...checkSource(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const apiData = response.data.hashtags;

      setData(apiData);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getListing();

    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, []);

  const getTableUI = () => {
    return (
      <table className="user-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Hash Tags</th>
            <th>Book</th>
            <th>Text</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data.map((row, i) => (
              <tr key={row.id}>
                <td>{i + 1}</td>
                <td>{row.name}</td>
                <td>{row.hashtag}</td>
                <td>{row.book}</td>
                <td>{row.text}</td>
                <td>{row.created_at}</td>
                <td>{row.updated_at}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                No Records found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Freelancer Client Hash Tags"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => {}}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
        </div>
      </div>

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default FreelancerClientsHashtag;
