import React from "react";

const LimitFilter = ({ selectedLimit, onChange }) => {
  const limit = [10, 20, 30, 40, 50];

  return (
    <div className="days-filter mt-2">
      {/* <label htmlFor="select-limit">Limit: </label> */}
      <select
        id="select-limit"
        className="form-select limit-dropdown"
        value={selectedLimit}
        onChange={(e) => onChange(parseInt(e.target.value))}
      >
        {limit.map((l, i) => (
          <option key={i} value={l}>
            {l}
          </option>
        ))}
      </select>
    </div >
  );
};

export default LimitFilter;
