import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import axios from "axios";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { hasPermission } from "../../../helperPermissionEditor";
import { LoginContext } from "../../../App";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import PaginationComponent from "../common-components/pagination/PaginationComponent";

const PhoneListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [phones, setPhones] = useState([]),
    [isEditing, setIsEditing] = useState(false),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [show, setShow] = useState(false),
    [selectedPhoneId, setSelectedPhoneId] = useState(null),
    [newPhone, setNewPhone] = useState({
      code: "",
      status: "",
      freelancer_id: "",
    }),
    [deletePhone, setDeletePhone] = useState(false),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [clientOption, setClientOption] = useState([]);

  const navigate = useNavigate();

  const fetchPhones = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/phones?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPhones(response.data.data);
      setTotalPages(response.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching phones:", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const handleClose = () => {
    setNewPhone({
      code: "",
      status: "",
      freelancer_id: "",
    });
    setShow(false);
  };

  useEffect(() => {
    fetchPhones();
    const intervalId = setInterval(() => {
      fetchPhones();
    }, 300000);
    return () => clearInterval(intervalId);
  }, []);

  const createPhoneList = async () => {
    setShow(true);
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(`/api/phones/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const labelValue = response?.data?.freelancers.map((client) => ({
        id: client.id,
        name: client.name,
      }));

      setClientOption(labelValue);
      setIsLoading(false);
      fetchPhones();
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.message || "Error creating freelancer");
      } else {
        console.error("Error creating freelancer:", error);
        toast.error("Failed to create freelancer. Please try again later.");
      }
    }
  };

  const handleEdit = async (index) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(
        `/api/phones/${phones[index].id}/edit`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const editedPhone = response.data.phone;
      setNewPhone({
        code: editedPhone.code,
        status: editedPhone.status,
        freelancer_id: editedPhone.freelancer_id,
      });
      setSelectedPhoneId(editedPhone.id);
      setIsEditing(index);
    } catch (error) {
      console.error("Error fetching phone for edit:", error);
    }
    setIsLoading(false);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");
    const updatedPhoneData = {
      code: newPhone.code,
      status: newPhone.status,
      freelancer_id: newPhone.freelancer_id,
    };

    setIsLoading(true);
    try {
      const response = await axiosInstance.put(
        `/api/phones/${selectedPhoneId}`,
        updatedPhoneData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsEditing(false);
      fetchPhones();
      toast.success("Phone updated successfully");
    } catch (error) {
      console.error("Error updating phone:", error);
      toast.error(error.response.data.message);
    }
    setIsLoading(false);
  };

  const handleDelete = async (phoneId) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/phones/${phoneId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPhones(phones.filter((phone) => phone.id !== phoneId));
      toast.success("Phone deleted successfully!");
      setDeletePhone(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
        window.location.reload();
      } else {
        console.error("Error deleting phone:", error);
        toast.error("Error deleting phone");
      }
    }
    setIsLoading(false);
  };

  const handleInputChange = (event, key) => {
    const value = event.target.value.toString(); // Ensure value is a string
    setNewPhone({ ...newPhone, [key]: value });
  };

  const handleSubmitPhone = async (event) => {
    event.preventDefault();

    const { code, freelancer_id } = newPhone;
    if (!code || !freelancer_id) {
      toast.error("Phone code and freelancer ID are required.");
      return;
    }
    setIsLoading(true);

    const token = localStorage.getItem("token");
    const phonePayload = {
      code: newPhone.code,
      freelancer_id: newPhone.freelancer_id,
    };

    try {
      const response = await axiosInstance.post(`/api/phones`, phonePayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setNewPhone({
        code: "",
        status: "",
        freelancer_id: "",
      });
      setIsLoading(false);
      setShow(false); // Close the modal after successful creation
      fetchPhones(); // Refresh phone list
      toast.success("Phone created successfully");
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.message || "Error creating phone");
      } else {
        toast.error("Failed to create phone. Please try again later.");
      }
      console.error("Error creating phone:", error);
    }
  };

  const filteredPhones = phones.filter((phone) =>
    phone.code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="phoneLists userLists">
      <CommonHeader
        dashboardName={"Phone"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={createPhoneList}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">
            <table className="user-table mt-4">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Code</th>
                  <th className="text-center">Status</th>
                  <th>Freelancer</th>
                  <th>Created By</th>
                  <th>Updated By</th>
                  <th>Created At</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredPhones.length > 0 ? (
                  filteredPhones.map((phone, index) => (
                    <tr
                      key={phone.id}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{phone.code}</td>
                      <td>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          className={
                            phone.status === "0"
                              ? "inactive-status"
                              : "active-status"
                          }
                        >
                          {phone.status === "0" ? <>InActive</> : <>Active</>}
                        </span>
                      </td>
                      <td>{phone.freelancer}</td>
                      <td>{phone.created_by}</td>
                      <td>{phone.updated_by}</td>

                      <td>{phone.created_at}</td>
                      <td>
                        {phone.code === "Super Admin" ? (
                          <span className="fs-6">
                            Admin phone is not Viewable
                          </span>
                        ) : (
                          <div className="d-flex ">
                            <Toaster position="top-center" richColors />
                            <button
                              data-tooltip-id="Edit-button"
                              className="edit me-2"
                              onClick={() => handleEdit(index)}
                              style={{
                                padding: "4px 10px",
                                fontSize: "22px",
                              }}
                              disabled={
                                !hasPermission(
                                  user?.permissions,
                                  "update-phone"
                                )
                              }
                            >
                              <TbEdit />
                            </button>
                            <ReactTooltip
                              id="Edit-button"
                              place="bottom"
                              content="Edit Phone"
                            />
                            <Toaster position="top-center" richColors />
                            <button
                              data-tooltip-id="delete-button"
                              className="edit delete"
                              style={{
                                background: "red",
                                color: "white",
                                fontSize: "22px",
                                padding: "4px 10px",
                              }}
                              onClick={() => handleDelete(phone.id)}
                              disabled={
                                !hasPermission(
                                  user?.permissions,
                                  "delete-phone"
                                )
                              }
                            >
                              <MdDelete />
                            </button>
                            <ReactTooltip
                              id="delete-button"
                              place="bottom"
                              content="Delete Phone"
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <div className="pagination">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                pageStart={pageStart}
              />
            </div>
          )}
        </div>
      </div>
      {isEditing !== false && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Phone</h5>
                <button
                  type="button"
                  className="model-popup-close"
                  onClick={() => setIsEditing(false)}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSave}>
                  <div>
                    <label className="mt-2 ms-2" htmlFor="code">
                      Phone Code
                    </label>
                  </div>
                  <input
                    type="text"
                    className="inputText"
                    value={newPhone.code}
                    onChange={(e) => handleInputChange(e, "code")}
                  />
                  <div>
                    <label className="mt-2 ms-2" htmlFor="status">
                      Phone Status
                    </label>
                  </div>
                  <select
                    className="form-control"
                    style={{
                      backgroundColor: "white !important",
                      width: "75%",
                      marginLeft: "1.5vh",
                    }}
                    value={newPhone.status}
                    onChange={(e) => handleInputChange(e, "status")}
                  >
                    <option value="0">In-Active</option>
                    <option value="1">Active</option>
                  </select>
                  <div>
                    <label className="mt-2 ms-2" htmlFor="freelancer_id">
                      Freelancer
                    </label>
                  </div>
                  <input
                    type="text"
                    className="inputText"
                    value={newPhone.freelancer_id}
                    onChange={(e) => handleInputChange(e, "freelancer_id")}
                  />
                  <button type="submit">Save</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header
          style={{ justifyContent: "center !important" }}
          className=" border-0  pb-0 text-center justify-content-center"
        >
          <div>
            <h2 className="create_user_heading">Create Phone</h2>
            <p className=" fs-5 mt-3 text-muted">
              create a Phone to manage and view your profile{" "}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="d-flex pt-0 justify-content-center ">
          <Form onSubmit={handleSubmitPhone}>
            <Form.Group className="mb-3" controlId="createPhone">
              <Form.Control
                type="text"
                className="inputText"
                value={newPhone.code}
                onChange={(e) => handleInputChange(e, "code")}
                placeholder="Phone Code"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formFreelancerRole">
              <Form.Select
                value={newPhone.freelancer_id}
                onChange={(e) => handleInputChange(e, "freelancer_id")}
              >
                <option>Select freelancer</option>
                {clientOption.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="primary"
                className="creatrUserSubmit me-3"
                type="submit"
              >
                Create Freelancer
              </Button>
              <Button
                variant="secondary"
                className="creatrUserClose"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default PhoneListing;
