import React, { useContext, useState } from "react";
import SignIn from "./sign-in/SignIn";
import SignUp from "./sign-up/Signup";
import { LoginContext } from "../../App";
import SignUpAsAccountManager from "./sign-up/SignUpAsAccountManager";



const Login = () => {

  const { createUser } = useContext(LoginContext)
  const { createAccountManager } = useContext(LoginContext)

  return (
    <div>
      {createUser ? (
        <SignUp />
      ) : createAccountManager ? (
        <SignUpAsAccountManager />
      ) : (
        <SignIn />
      )}
    </div>
  );
};

export default Login;
