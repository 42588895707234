import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit, TbRestore } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Alert from "react-bootstrap/Alert";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { hasPermission } from "../../../helperPermissionEditor";
import { LoginContext } from "../../../App";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../common-components/customModal";

const userData = {
  name: "",
  email: "",
  phone: "",
  password: "",
  address: "",
  tiktok: "",
  status: "",
  id: "",
};
const settingData = {
  visible: false,
  tiktok: 0,
  instagram: 0,
  clientId: 0,
};
const AddTiktokAccounts = () => {
  const { user } = useContext(LoginContext),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [editedStatus, setEditedStatus] = useState(""),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [newUser, setNewUser] = useState(userData),
    [list, setList] = useState([]),
    [show, setShow] = useState(false),
    [edit, setEdit] = useState(""),
    [settings, setSettings] = useState(settingData),
    [deleteTiktok, setDeleteTiktok] = useState(false),
    [selectedTiktokId, setSelectedTiktokId] = useState(null),
    [handleRestore, setHandleRestore] = useState(false),
    [remainingTime, setRemainingTime] = useState(""),
    [lastSyncTime, setLastSyncTime] = useState(localStorage.getItem("last_sync_time"));

  const resetFields = () => {
    setNewUser(userData);
  };

  const handleEditList = (e, record) => {
    setShow(true);
    setEdit(record);

    setNewUser((prevState) => ({
      ...prevState,
      tiktok: record.tiktok,
    }));
    setEditedStatus(record.status);
  };

  const handleClose = () => {
    setShow(false);
    resetFields();
    setEditedStatus("");
    setEdit("");
  };

  const getTiktoks = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/tiktoks`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          client_id: Number(localStorage.getItem("client_id")),
        },
      });

      if (response.data?.tiktoks.length) {
        setList(response?.data?.tiktoks);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        setSettings({
          visible: true,
          tiktok: 0,
          instagram: 0,
          clientId: Number(localStorage.getItem("client_id")),
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTiktoks();
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  const handleDelete = async (tiktokId) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/tiktoks/${tiktokId}`, {
        params: {
          client_id: Number(localStorage.getItem("client_id")),
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      getTiktoks();
      toast.success("TikTok account deleted successfully!");
      setDeleteTiktok(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error deleting TikTok account");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestoreTiktok = async (tiktokId) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      await axiosInstance.put(
        `/api/tiktoks/${tiktokId}/restore`,
        {
          client_id: Number(localStorage.getItem("client_id")),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("TikTok account restored successfully!");
      getTiktoks();
      setHandleRestore(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error restoring TikTok account");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchUserDetails = async () => {
    const userConfirmed = window.confirm(
      "Please add at least 5 accounts before syncing. Do you want to continue anyway?"
    );

    if (!userConfirmed) {
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.post(
        '/api/tiktok/fetch-tiktok-user-details',
        {
          user_id: Number(localStorage.getItem("client_id")),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success(response.data.message);

      if (response.status === 200 && response.data?.client?.last_sync_time) {
        const newLastSyncTime = response.data.client.last_sync_time;
        setLastSyncTime(newLastSyncTime);
        localStorage.setItem("last_sync_time", newLastSyncTime);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred. Please try again later.";
      console.error(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to calculate remaining time
  const calculateRemainingTime = () => {
    if (!lastSyncTime) {
      return "You can sync now!";
    }

    const now = new Date();
    const lastSyncDate = new Date(lastSyncTime + " UTC");
    const nextSyncDate = new Date(lastSyncDate.getTime() + 24 * 60 * 60 * 1000);
    const diff = nextSyncDate - now;

    if (diff > 0) {
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      return `${hours}h ${minutes}m ${seconds}s`;
    } else {
      return "You can sync now!";
    }
  };

  useEffect(() => {
    // Update the countdown every second
    const intervalId = setInterval(() => {
      const updatedTime = calculateRemainingTime();
      setRemainingTime(updatedTime);
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [lastSyncTime]);

  const addTiktokAccountsAPICall = async () => {
    const { tiktok } = newUser;

    if (!tiktok) {
      toast.error("Please fill in the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      client_id: Number(localStorage.getItem("client_id")),
      tiktok,
    };

    try {
      if (edit) {
        await axiosInstance.put(
          `/api/tiktoks/${edit.id}`,
          {
            ...payload,
            status: editedStatus,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        await axiosInstance.post(
          `/api/tiktoks`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      setIsLoading(false);
      setEdit("");
      setEditedStatus("");
      setNewUser((prevState) => ({
        ...prevState,
        tiktok: "",
      }));
      setShow(false);
      getTiktoks();
      toast.success(`${edit ? "Updated" : "Created"} successfully`);
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error.response?.data?.message ||
        error.response?.data?.status ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      console.error("Error while creating/updating:", error.response?.data || error);
    }
  };

  const addEditTiktokAccounts = () => {
    return (
      <>
        <Form.Group
          className="mb-3"
          controlId="exampleForm.ControlInput3"
        >
          <Form.Control
            type="text"
            autoFocus
            style={{ display: "inline-block" }}
            value={newUser.tiktok}
            onChange={(e) =>
              setNewUser((prevState) => ({
                ...prevState,
                tiktok: e.target.value,
              }))
            }
            required
            placeholder="Tiktok@id"
            disabled={edit?.tiktok}
          />
        </Form.Group>

        {editedStatus && (
          <Form.Group className="mb-3">
            <select
              id="formClientStatus"
              className="form-select"
              value={editedStatus}
              onChange={(e) => setEditedStatus(e.target.value)}
            >
              <option disabled value="">
                Select Status
              </option>
              <option key={1} value="1">
                Active
              </option>
              <option key={0} value="0">
                In-Active
              </option>
            </select>
          </Form.Group>
        )}
      </>
    );
  };

  return (
    <div className=" clientLists userLists">
      <Toaster position="top-center" richColors />
      <CommonHeader
        dashboardName={"Tiktok Accounts"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => {
          setShow(true);
        }}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="sync-container">
            <div className="sync-content">
              <button
                disabled={isLoading}
                className={`sync-button creatrUserSubmit btn btn-primary ${isLoading ? "loading" : ""}`}
                onClick={handleFetchUserDetails}
              >
                {isLoading ? "Syncing..." : "Sync TikTok Details"}
              </button>

              <div className="countdown-timer">
                <span className="countdown-label">Next Sync Available In:</span>
                <p className="countdown-value">{remainingTime}</p>
              </div>
            </div>
          </div>
          <div className="userDetails">
            <div className="details mt-4">
              {list.length > 0 ? (
                <table
                  class="table table-striped"
                  style={{
                    height: "auto",
                    maxHeight: "30vh",
                    overflowX: "auto",
                  }}
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Deleted at</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.map((record, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <Link to={`/Tiktok-statistics/${record?.id}`}>
                            {record.tiktok}
                          </Link>
                        </td>

                        <td>{Number(record.status) ? "Active" : "InActive"}</td>
                        <td>{record.deleted_at}</td>

                        <td>

                          <button
                            data-tooltip-id={record.deleted_at ? "Restore-button" : "Edit-button"}
                            className={record.deleted_at ? "restore me-2" : "edit me-2"}
                            onClick={(e) => record.deleted_at ? (setHandleRestore(true), setSelectedTiktokId(record.id)) : handleEditList(e, record)}
                            style={{
                              padding: "4px 10px",
                              fontSize: "22px",
                            }}
                          >
                            {record.deleted_at ? <TbRestore /> : <TbEdit />}
                          </button>
                          <ReactTooltip
                            id="Edit-button"
                            place="bottom"
                            content="Edit tiktok Account"
                          />
                          <ReactTooltip
                            id="Restore-button"
                            place="bottom"
                            content="Restore tiktok Account"
                          />
                          <ReactTooltip
                            id="Delete-button"
                            place="bottom"
                            content="Delete tiktok Account"
                          />
                          {hasPermission(
                            user.permissions,
                            "delete-tiktok"
                          ) && (
                              <button
                                data-tooltip-id="Delete-button"
                                className="delete edit"
                                style={{
                                  background: "red",
                                  color: "white",
                                  fontSize: "22px",
                                  padding: "4px 10px",
                                }}
                                onClick={() => {
                                  setDeleteTiktok(true);
                                  setSelectedTiktokId(record.id);
                                }}
                                disabled={record?.deleted_at ? 'disabled' : ''}
                              >
                                <MdDelete />
                              </button>
                            )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    No Accounts found
                  </td>
                </tr>
              )}
            </div>
          </div>
          <div>
            {totalPages > 1 && (
              <div className="pagination">
                <PaginationComponent
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  pageStart={pageStart}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add / Edit Modal */}
      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <h4 className="create_user_heading">
            {edit?.tiktok ? `Update` : `Add`} Account
          </h4>
        }
        bodyContent={
          <>
            <div>{addEditTiktokAccounts()}</div>
          </>
        }
        footerContent={
          <>
            <Button
              variant="primary"
              className="creatrUserSubmit"
              onClick={addTiktokAccountsAPICall}
            >
              {edit?.tiktok ? `Update` : `Add`} Account
            </Button>
            <Button
              variant="secondary"
              className="creatrUserClose"
              onClick={handleClose}
            >
              Close
            </Button>
          </>
        }
      />
      {
        isLoading && (
          <div className="loader-overlay">
            <CommonLoader />
          </div>
        )
      }

      {/* Delete Confirmation Modal */}
      <CustomModal
        show={deleteTiktok}
        handleClose={() => setDeleteTiktok(false)}
        headerContent={<h4>Confirm Delete</h4>}
        bodyContent={<p>Are you sure you want to delete this Tiktok account?<br /> Deleted account information not synced with tiktok.</p>}
        footerContent={
          <>
            <Button
              variant="danger"
              onClick={() => handleDelete(selectedTiktokId)}
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Confirm"}
            </Button>
            <Button
              variant="secondary"
              className=""
              onClick={() => setDeleteTiktok(false)}>
              Close
            </Button>
          </>
        }
      />
      {/* Delete Confirmation Modal */}
      <CustomModal
        show={handleRestore}
        handleClose={() => setHandleRestore(false)}
        headerContent={<h4>Confirm Restore</h4>}
        bodyContent={<p>Are you sure you want to restore this Tiktok account?</p>}
        footerContent={
          <>
            <Button
              variant="danger"
              onClick={() => handleRestoreTiktok(selectedTiktokId)}
              disabled={isLoading}
            >
              {isLoading ? "Restoring..." : "Confirm"}
            </Button>
            <Button
              variant="secondary"
              className=""
              onClick={() => setHandleRestore(false)}>
              Close
            </Button>
          </>
        }
      />
    </div >
  );
};

export default AddTiktokAccounts;
