import React, { useContext, useRef } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { SlSizeFullscreen } from "react-icons/sl";
import { MdFullscreenExit } from "react-icons/md";
import { LoginContext } from "../../../../App";

function CustomCard({ style, header, children, id }) {
  const { theme, fullScreen, setFullScreen } = useContext(LoginContext);

  const toggleFullScreen = () => {
    const buttonId = document.getElementById(id);

    if (buttonId) {
      if (!document.fullscreenElement) {
        setFullScreen(true);
        if (buttonId.requestFullscreen) {
          buttonId.requestFullscreen().catch((err) => {
            console.error(`Error enabling full-screen mode: ${err.message}`);
          });
        } else if (buttonId.webkitRequestFullscreen) {
          /* Safari */
          buttonId.webkitRequestFullscreen();
        } else if (buttonId.msRequestFullscreen) {
          /* IE11 */
          buttonId.msRequestFullscreen();
        }
      } else {
        document.exitFullscreen();
        setFullScreen(false);
      }
    }
  };

  return (
    <Card className="bg-chart" style={{ height: style }}>
      {header && (
        <Card.Header className=" d-flex justify-content-between pd-2">
          <span>{header}</span>

          {id && (
            <span>
              <Button
                data-tooltip-id="fullscreen-button"
                className="delete edit"
                style={{
                  background: theme === "dark" ? "#000000" : "#ffffff",
                  color: theme === "dark" ? "#ffffff" : "#000000",
                }}
                onClick={toggleFullScreen}
              >
                {fullScreen ? <MdFullscreenExit /> : <SlSizeFullscreen />}
              </Button>
            </span>
          )}
        </Card.Header>
      )}
      <Card.Body>{children}</Card.Body>
    </Card>
  );
}

export default CustomCard;
