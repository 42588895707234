import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const TikTokAccountFilters = ({ clients, onFilterChange }) => {
  const initialFilters = {
    client_id: "",
    follower_count_from: "",
    follower_count_to: "",
    following_count_from: "",
    following_count_to: "",
    total_favorited_from: "",
    total_favorited_to: "",
    visible_videos_count_from: "",
    visible_videos_count_to: "",
    from: "",
    to: "",
    sort_by: "average_views",
    order_by: null,
  };

  const [filters, setFilters] = useState(initialFilters);
  const [hasAdminRole, setHasAdminRole] = useState(false);

  // Use useEffect to access localStorage after component mounts
  useEffect(() => {
    const role = localStorage.getItem("userRole");
    if (role === "super-admin" || role === "admin") {
      setHasAdminRole(true);
    } else {
      setHasAdminRole(false);
    }
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const applyFilters = () => {
    onFilterChange(filters); // Apply filters to parent component
  };

  const resetFilters = () => {
    setFilters({ ...initialFilters }); // Reset to initial state
    onFilterChange({ ...initialFilters }); // Notify parent component about reset filters
  };

  // Reusable input function for numeric range
  const renderRangeInput = (label, nameFrom, nameTo, valueFrom, valueTo) => (
    <div className="col-md-6 mb-3" key={`${nameFrom}-${nameTo}`}>
      <label>{label} (From - To)</label>
      <div className="d-flex">
        <input
          type="number"
          name={nameFrom}
          value={valueFrom}
          onChange={handleFilterChange}
          className="form-control me-2"
          placeholder="From"
        />
        <input
          type="number"
          name={nameTo}
          value={valueTo}
          onChange={handleFilterChange}
          className="form-control"
          placeholder="To"
        />
      </div>
    </div>
  );

  // Reusable input function for date range
  const renderDateInput = () => (
    <div className="col-md-6 mb-3" key="date-filter">
      <label>Date (From - To)</label>
      <div className="d-flex">
        <input
          type="date"
          name="from"
          value={filters.from}
          onChange={handleFilterChange}
          className="form-control me-2"
        />
        <input
          type="date"
          name="to"
          value={filters.to}
          onChange={handleFilterChange}
          className="form-control"
        />
      </div>
    </div>
  );

  return (
    <div className="container p-3">
      <div className="row">
        {/* Follower Count */}
        {renderRangeInput(
          "Follower Count",
          "follower_count_from",
          "follower_count_to",
          filters.follower_count_from,
          filters.follower_count_to
        )}

        {/* Following Count */}
        {renderRangeInput(
          "Following Count",
          "following_count_from",
          "following_count_to",
          filters.following_count_from,
          filters.following_count_to
        )}

        {/* Total Favorited */}
        {renderRangeInput(
          "Total Favorited",
          "total_favorited_from",
          "total_favorited_to",
          filters.total_favorited_from,
          filters.total_favorited_to
        )}

        {/* Visible Videos Count */}
        {renderRangeInput(
          "Visible Videos Count",
          "visible_videos_count_from",
          "visible_videos_count_to",
          filters.visible_videos_count_from,
          filters.visible_videos_count_to
        )}

        {/* Date Filter */}
        {renderDateInput()}

        {/* Filter by Client */}
        {hasAdminRole && (
          <div className="col-md-6 mb-3" key="client-filter">
            <label htmlFor="client">Filter by Client:</label>
            <select
              className="form-select"
              id="client_id"
              name="client_id"
              value={filters.client_id}
              onChange={handleFilterChange}
            >
              <option value="">All Clients</option>
              {clients.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="col-md-6 mb-3" key="sortby-filter">
          <label htmlFor="tiktokUser">Filter by :</label>
          <select
            className="form-select"
            id="sort_by"
            name="sort_by"
            value={filters.sort_by}
            onChange={handleFilterChange}
          >
            <option value="">Sort by</option>
            <option key="average_views" value="average_views">
              Average Views
            </option>
            <option key="average_interactions" value="average_interactions">
              Average Interactions
            </option>
            <option key="average_engagement" value="average_engagement">
              Engagements
            </option>
          </select>
        </div>

        <div className="col-md-6 mb-3" key="orderby-filter">
          <label htmlFor="tiktokUser">Order by :</label>
          <select
            className="form-select"
            id="order_by"
            name="order_by"
            value={filters.order_by}
            onChange={handleFilterChange}
          >
            <option value="">Order by</option>
            <option key="asc" value="asc">
              Ascending
            </option>
            <option key="desc" value="desc">
              Descending
            </option>
          </select>
        </div>
      </div>

      {/* Buttons */}
      <div className="row mt-3">
        <div className="col-md-12">
          <button className="btn btn-primary me-2" onClick={applyFilters}>
            Apply Filters
          </button>
          <button className="btn btn-secondary" onClick={resetFilters}>
            Reset Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default TikTokAccountFilters;
