import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
  baseURL: apiUrl,
});
let callAgain = true;
let timeoutIds = [];

const refreshToken = async () => {
  const token1 = localStorage.getItem("token");
  if (token1) {
    try {
      const response = await axiosInstance.post("/api/refresh");
      const { token, expires_in } = response.data;
      localStorage.setItem("token", token);
      console.log("Refreshed Token!");
      scheduleTokenRefresh(expires_in);
    } catch (error) {
      console.error("Failed to refresh token:", error);
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
  }
  callAgain = true;
};

const scheduleTokenRefresh = (expiresIn) => {
  const refreshTime = (expiresIn - 60) * 1000;
  const timeoutId = setTimeout(() => {
    refreshToken();
  }, refreshTime);
  timeoutIds.push(timeoutId);
};

export const clearAllTimeouts = () => {
  timeoutIds.forEach((id) => clearTimeout(id));
  timeoutIds = [];
};

export const initializeTokenRefresh = () => {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      const { exp } = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      const expiresIn = exp - currentTime;
      if (expiresIn > 0) {
        scheduleTokenRefresh(expiresIn);
      } else {
        refreshToken();
      }
    } else {
      refreshToken();
    }
    callAgain = false;
  } catch (error) {
    console.error("Failed to initialize token:", error);
  }
};

// Request interceptor
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    // if (callAgain) {
    //   initializeTokenRefresh();
    // }
  }
  return config;
});

export default axiosInstance;
