import React, { useEffect, useState, useContext } from "react";
import { Toaster, toast } from "sonner";
import "./pages.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import CommonLoader from "../../../loader/CommonLoader";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CommonHeader from "../common-components/CommonHeader";
import axiosInstance from "../../../refershTokenManage/axiosConfig";
import { LoginContext } from "../../../App";
import { hasPermission } from "../../../helperPermissionEditor";
import PaginationComponent from "../common-components/pagination/PaginationComponent";
import CustomModal from "../common-components/customModal";
import { LuEye } from "react-icons/lu";
import NoDataFoundPage from "../common-components/NoDataFound";

const SoundListing = ({ onUserSelect }) => {
  const { user } = useContext(LoginContext),
    [sounds, setSounds] = useState([]),
    [isEditing, setIsEditing] = useState(""),
    [isLoading, setIsLoading] = useState(false),
    [searchQuery, setSearchQuery] = useState(""),
    [currentPage, setCurrentPage] = useState(1),
    [totalPages, setTotalPages] = useState(1),
    [pageStart, setPageStart] = useState(1),
    [record, setRecord] = useState({
      sound: "",
      label_id: "",
    }),
    [view, setView] = useState({
      visible: false,
    }),
    [options, setOptions] = useState([]),
    [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRecord({
      title: "",
      type: "",
    });
    setIsEditing("");
    setView({});
  };
  const navigate = useNavigate();

  const getListing = async (page = 1) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/api/sounds`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setSounds(response.data.sounds);
      setTotalPages(response.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching Data", error);
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
    // Adjust pageStart based on the current page and desired behavior
    const newPageStart = Math.floor((page - 1) / 5) * 5 + 1;
    setPageStart(newPageStart);
  };

  useEffect(() => {
    getListing(currentPage);
    handleShow();

    const intervalId = setInterval(() => {
      getListing();
    }, 300000);

    return () => clearInterval(intervalId);
  }, [currentPage]);

  const handleShow = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axiosInstance.get(`/api/sounds/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setOptions(response?.data?.label || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error(error.response.data.message || "Error creating freelancer");
      } else {
        console.error("Error creating freelancer:", error);
        toast.error("Failed to create freelancer. Please try again later.");
      }
    }
  };

  const handleEdit = async (row) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axiosInstance.get(`/api/sounds/${row.id}/edit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);

      setShow(true);

      const { sound, label_id } = response.data.sound;

      setIsEditing(response.data.sound);

      setRecord((prevState) => ({
        ...prevState,
        sound,
        label_id,
      }));
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching Record for edit:", error);
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem("token");

    setIsLoading(true);
    try {
      await axiosInstance.delete(`/api/sounds/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSounds(sounds.filter((sound) => sound.id !== id));

      toast.success("Record deleted successfully!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.clear();
        navigate("/");
      } else {
        console.error("Error deleting Record:", error);
        toast.error("Error deleting Record");
      }
    }
    setIsLoading(false);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");

    const updatedData = {
      sound: record.sound,
      label_id: record.label_id,
    };

    try {
      const response = await axiosInstance.put(
        `/api/sounds/${isEditing.id}`,
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      handleClose();
      toast.success("Record updated successfully");
      getListing();
      if (response.status === 201) {
        setIsEditing("");
      } else {
        console.error("Error while updating:", response);
      }
    } catch (error) {
      console.error("Error while updating:", error);
      toast.error("Please try again");
    }
  };

  const handleCreate = async () => {
    const { sound, label_id } = record;

    // Validate the form fields
    if (
      !sound
      // || !label_id
    ) {
      toast.error("Please fill in all the fields before submitting.");
      return;
    }

    setIsLoading(true);
    const token = localStorage.getItem("token");
    const payload = {
      sound,
      label_id,
    };

    try {
      await axiosInstance.post(`/api/sounds`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsLoading(false);
      getListing();
      toast.success("Record created successfully");
      handleClose();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message || "Error creating Record");
      console.error("Error creating Record:", error);
    }
  };
  const handleView = (record) => {
    setView({
      visible: true,
      ...record?.label,
    });
    setShow(true);
  };

  const getTableUI = () => {
    return (
      <table className="user-table mt-4">
        <thead>
          <tr>
            <th>ID</th>
            <th>Label</th>
            <th>Sound</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {sounds.length > 0 ? (
            sounds.map((row, index) => (
              <tr key={row.id}>
                <td>{index + 1}</td>
                <td>{row.label}</td>
                <td>{row.sound}</td>
                <td>{new Date(row.created_at).toLocaleDateString()}</td>
                <td>{new Date(row.updated_at).toLocaleDateString()}</td>
                <td className="d-flex align-items-center justify-content-center">
                  {/* <Toaster position="top-center" richColors />
                    <button
                      data-tooltip-id="view-button"
                      className="edit me-2"
                      onClick={() => handleView(row)}
                      style={{
                        padding: "4px 10px",
                        fontSize: "22px",
                      }}
                      disabled={!hasPermission(user?.permissions, "view-sound")}
                    >
                      <LuEye />
                    </button>
                    <ReactTooltip
                      id="view-button"
                      place="bottom"
                      content="View Tiktok"
                    /> */}

                  <span
                    className="px-2 edit me-2"
                    style={{ cursor: "pointer" }}
                  >
                    <TbEdit
                      size={24}
                      data-tooltip-id="editTooltip"
                      data-tooltip-content="Edit"
                      onClick={() => handleEdit(row)}
                      disabled={
                        !hasPermission(user?.permissions, "update-sound")
                      }
                    />
                    <ReactTooltip id="editTooltip" />
                  </span>

                  <span
                    className="px-2"
                    style={{
                      cursor: "pointer",
                      background: "red",
                      color: "white",
                      fontSize: "16px",
                      padding: "8px 10px",
                      borderRadius: "8px",
                    }}
                  >
                    <MdDelete
                      size={24}
                      data-tooltip-id="deleteTooltip"
                      data-tooltip-content="Delete"
                      onClick={() => handleDelete(row.id)}
                      disabled={
                        !hasPermission(user?.permissions, "delete-sound")
                      }
                    />
                    <ReactTooltip id="deleteTooltip" />
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                <NoDataFoundPage />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  const getFormUI = () => {
    return view?.visible ? (
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {[view].map((record, i) => (
            <tr key={i}>
              <td>{record?.id}</td>
              <td>{record.title}</td>
              <td>{record.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <div style={{ justifyContent: "center !important" }}>
        <Form.Group className="mb-3" controlId="formFreelancerName">
          <Form.Control
            type="text"
            placeholder="Enter Sound"
            value={record.sound}
            onChange={(e) => setRecord({ ...record, sound: e.target.value })}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formFreelancerRole">
          <Form.Select
            value={record.label_id}
            onChange={(e) =>
              setRecord({
                ...record,
                label_id: e.target.value,
              })
            }
          >
            <option>Labels Options</option>
            {options.map((option, index) => (
              <option key={index} value={option.id}>
                {option.title}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
    );
  };

  return (
    <div className="clientLists userLists">
      <CommonHeader
        dashboardName={"Sound"}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        handleShow={() => setShow(true)}
        user={user}
      />
      <div className="mt-3">
        <div className="userFetchData">
          <div className="userDetails">{getTableUI()}</div>
          {totalPages > 1 && (
            <div className="pagination">
              <PaginationComponent
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
                pageStart={pageStart}
              />
            </div>
          )}
        </div>
      </div>

      <CustomModal
        show={show}
        handleClose={handleClose}
        headerContent={
          <div>
            {view?.visible ? (
              <h2 className="create_user_heading"> Labels List </h2>
            ) : (
              <h2 className="create_user_heading">{`${
                isEditing ? "Update" : "Create"
              } Sounds`}</h2>
            )}
          </div>
        }
        bodyContent={getFormUI()}
        footerContent={
          <>
            <div className="d-flex justify-content-center align-items-center">
              {!view?.visible && (
                <Button
                  variant="primary"
                  className="creatrUserSubmit me-3"
                  onClick={isEditing ? handleUpdate : handleCreate}
                >
                  {`${isEditing ? "Update" : "Create"} Sounds`}
                </Button>
              )}
              <Button
                variant="secondary"
                className="creatrUserClose"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        }
      />

      {isLoading && (
        <div className="loader-overlay">
          <CommonLoader />
        </div>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default SoundListing;
