import React from "react";

const HashTagFilter = ({ selectedLimit, onChange }) => {
    const limit = [5, 10, 15];

    return (
        <div className="days-filter">
            <select
                id="select-limit"
                value={selectedLimit}
                className="form-select limit-dropdown"
                onChange={(e) => onChange(parseInt(e.target.value))}
            >
                {limit.map((l, i) => (
                    <option key={i} value={l}>
                        {l}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default HashTagFilter;
